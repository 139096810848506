import React, { FC } from 'react'
import loadingBarsImg from "../images/loading-bars.svg"
import styled from "styled-components"
import media from "styled-media-query"

interface LoadingBarProps {
  caption?: string
  className?: string
}

const LoadingBars: FC<LoadingBarProps> = ({caption, className}) => {
  return (
    <LoadingBarsContainer className={className}>
      <LoadingBarsImage src={loadingBarsImg} alt="loading-bars"/>
      { caption &&
          <LoadingBarsCaption>
            {caption}
          </LoadingBarsCaption>
      }
    </LoadingBarsContainer>
  );
}

const LoadingBarsContainer = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoadingBarsImage = styled.img`
    width: 20%;
    height: 20%;
    fill: red;
`;

const LoadingBarsCaption = styled.div`
    margin: 20px 40px;
    text-align: center;
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
    ${media.greaterThan("medium")`
      font-size: 20px;
    `}
`;

export default LoadingBars