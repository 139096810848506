import React, {FC, memo, ReactElement, ReactNode, useState} from "react";
import * as S from "./styles";

type TabProps = {
  onTabSelected: (selectedTab: number) => void
  children: ReactNode[] | ReactNode
}
const Tab: FC<TabProps> = ({ children, onTabSelected }) => {
  const [itemId, setItemId] = useState(0);

  return (
    <S.TabContainer>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child as ReactElement<any>, {
          onClick: () => {
            setItemId(index);
            onTabSelected(index);
          },
          selected: itemId === index
        });
      })}
    </S.TabContainer>
  );
};

type TabItemProps = {
  children: ReactNode[] | ReactNode
}

export const TabItem: FC<TabItemProps> = memo(({ children, ...restProps }) => (
  <S.TabItem {...restProps}>{children}</S.TabItem>
));

export default Tab;
