import React, { FC, ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { signup } from './actions';
import {bindActionCreators, compose, Dispatch} from 'redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import media, { defaultBreakpoints } from 'styled-media-query';
import { useHistory } from "react-router-dom";

import Button from './Button';
import { AuthState, LoadingState, RootState } from './types/States';
import { useMediaQuery } from 'react-responsive';
import * as analyticsHelper from './common/analyticsHelper';
import * as keys from './common/keys';
import LoadingBars from "./components/LoadingBars"

const StyledForm = styled.form`
  ${media.greaterThan("medium")`
    max-width: 82%;
  `}
  ${media.greaterThan("large")`
    max-width: 65%;
  `}
`;

const StyledText = styled.p`
  margin: 0 0 18px 0;
`;

const SigninLink = styled(Link)`
  color: ${props => props.theme.colors.orange};
  text-decoration: none;
  transition: all 0.1s linear;
  display: block;
  text-align: center;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0.8125em 0;
  padding: 1em;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
  background-color: white;
  min-width: 0px;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family: Gibson;
  color: ${props => props.theme.colors.orange};
  line-height: 1em;
  margin: 0 0 0.8125em 0;
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  width: 100%;
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  max-width: 1020px;
  margin: auto;
  display: flex;
  justify-content: center;
`;

type SignupProps = {
  signup: typeof signup
  isLoading: LoadingState["isLoading"]
  currentUser: AuthState["currentUser"]
}

const SignupForm: FC<SignupProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageSignup);
    window.scrollTo(0, 0);
  }, []);

  const isDesktop = useMediaQuery({
    minWidth: defaultBreakpoints.medium
  });
  const passwordPlaceHolderText = isDesktop ? 'Password (at least 8 characters you won\'t forget!)' : 'Password (at least 8 characters)';

  const [soundCloudProfileUrl, setSoundCloudProfileUrl] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [soundCloudProfileUrlValidationMessage, setSoundCloudProfileUrlValidationMessage] = useState('* SoundCloud profile URL cannot be blank.');
  const [shouldShowSoundCloudProfileUrlValidationMessage, setShouldShowSoundCloudProfileUrlValidationMessage] = useState(false);

  const [nameValidationMessage, setNameValidationMessage] = useState('* You must tell us who you are. Don\'t be shy!.');
  const [shouldShowNameValidationMessage, setShouldShowNameValidationMessage] = useState(false);

  const [emailValidationMessage, setEmailValidationMessage] = useState('* Email cannot be blank!');
  const [shouldShowEmailValidationMessage, setShouldShowEmailValidationMessage] = useState(false);

  const [passwordValidationMessage, setPasswordValidationMessage] = useState('* You can\'t have an empty password. That would be too easy to guess!');
  const [shouldShowPasswordValidationMessage, setShouldShowPasswordValidationMessage] = useState(false);


  const isSoundCloudProfileUrlValid = (url: string): boolean => {
    return /^(http[s]?:\/)?\/?(m.)?soundcloud.com\/([\w\-.]+)\/?(?:\?|$)|^(http[s]?:\/)?\/?on.soundcloud.com\/([\w\-.]+)\/?(?:\?|$)/.test(url)
  }

  const handleSoundCloudProfileUrlChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setSoundCloudProfileUrl(evt.target.value);
    if (!evt.target.value) {
      setSoundCloudProfileUrlValidationMessage('* SoundCloud profile URL cannot be blank.')
    } else if (!isSoundCloudProfileUrlValid(evt.target.value)) {
      setSoundCloudProfileUrlValidationMessage('* Invalid SoundCloud profile URL. A valid one looks like soundcloud.com/your-profile-name')
    }
    else {
      setSoundCloudProfileUrlValidationMessage('');
      setShouldShowSoundCloudProfileUrlValidationMessage(false)
    }
  };

  const handleNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setName(evt.target.value);
    if (!evt.target.value) {
      setNameValidationMessage('* You must tell us who you are. Don\'t be shy!');
    } else {
      setNameValidationMessage('');
      setShouldShowNameValidationMessage(false);
    }
  };

  const handleEmailChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
    if (!evt.target.value) {
      setEmailValidationMessage('* Email cannot be blank!');
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(evt.target.value)) {
      setEmailValidationMessage('* We need a valid email address in order to create an account for you.');
    }
    else {
      setEmailValidationMessage('');
      setShouldShowEmailValidationMessage(false);
    }
  };

  const handlePasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
    if (!evt.target.value) {
      setPasswordValidationMessage('* You can\'t have an empty password. That would be too easy to guess!');
    } else if (evt.target.value.length < 8) {
      setPasswordValidationMessage('* Your password must be at least 8 characters long.');
    }
    else {
      setPasswordValidationMessage('');
      setShouldShowPasswordValidationMessage(false);
    }
  };

  const inputsAreValid = () => {
    const isProfileUrlValid = isSoundCloudProfileUrlValid(soundCloudProfileUrl);
    const isNameValid = name;
    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    const isPasswordValid = password.length >= 8;

    if (!isProfileUrlValid) {
      setShouldShowSoundCloudProfileUrlValidationMessage(true);
    }

    if (!isNameValid) {
      setShouldShowNameValidationMessage(true);
    }
    if (!isEmailValid) {
      setShouldShowEmailValidationMessage(true);
    }
    if (!isPasswordValid) {
      setShouldShowPasswordValidationMessage(true);
    }

    return isProfileUrlValid && isNameValid && isEmailValid && isPasswordValid;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    analyticsHelper.recordClick(keys.AmpElementSignupButton);
    if (inputsAreValid()) {
      let profileUrl = soundCloudProfileUrl
      if (!/^https?:\/\//i.test(profileUrl)) {
        profileUrl = 'https://' + profileUrl;
      }
      const webURLRegex = /^(http[s]?:\/)?\/?(m.)?soundcloud.com\/([\w\-.]+)\/?(?:\?|$)/
      const matches = profileUrl.match(webURLRegex)

      if (matches) {
        const scUsername = matches[matches.length - 1]
        profileUrl = 'https://soundcloud.com/' + scUsername
      }

      props.signup({
        soundCloudProfileUrl: profileUrl,
        firstName: name,
        email,
        password,
      }, () => {
        history.push('/dashboard');
      });
    }
  };

  const renderRedirect = () => {
    if (props.currentUser) {
      return <Redirect to='/dashboard' />
    }
  };

  const renderLoadingOrMainContent = () => {
    if (props.isLoading) {
      return (
        <LoadingBars caption='Verifying your information and creating your account...'/>
      )
    } else {
      return (
        <StyledContent>
          <StyledForm onSubmit={handleSubmit}>
            <StyledHeading>Grow your fanbase the right way</StyledHeading>
            <StyledText>
              Artist Management has been helping musicians across the globe by putting their music in front of their biggest fans. Create an account and you'll be on your way getting your music heard.
            </StyledText>
            <StyledInput
              type="text"
              value={soundCloudProfileUrl}
              onChange={handleSoundCloudProfileUrlChange}
              placeholder="SoundCloud profile URL"
            />
            {
              shouldShowSoundCloudProfileUrlValidationMessage &&
                <StyledInputValidationText>
                  {soundCloudProfileUrlValidationMessage}
                </StyledInputValidationText>
            }
            <StyledInput
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="First name (or nickname)"
            />
            { shouldShowNameValidationMessage &&
                <StyledInputValidationText>
                  {nameValidationMessage}
                </StyledInputValidationText>
            }
            <StyledInput
              type="text"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email address"
            />
            { shouldShowEmailValidationMessage &&
                <StyledInputValidationText>
                  {emailValidationMessage}
                </StyledInputValidationText>
            }
            <StyledInput
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder={passwordPlaceHolderText}
            />
            { shouldShowPasswordValidationMessage &&
                <StyledInputValidationText>
                  {passwordValidationMessage}
                </StyledInputValidationText>
            }
            <StyledButton
              disabled={props.isLoading}
            >
              sign up
            </StyledButton>
            <SigninLink to="/signin">
              If you've already done this before, click here to sign in
            </SigninLink>
          </StyledForm>
        </StyledContent>
      )
    }
  }

  return (
    <StyledContainer>
      {renderRedirect()}
      {renderLoadingOrMainContent()}
    </StyledContainer>
  );
};

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.currentUser,
    isLoading: state.loading.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators( { signup }, dispatch )
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SignupForm)
