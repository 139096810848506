import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { SelectedPlanType } from '../types/Users';
import { AuthState, RootState } from '../types/States'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FaStar } from 'react-icons/fa6'

const StyledPlanTitle = styled.div`
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
`;
const StyledPlanPrice = styled(StyledPlanTitle)`
    font-size: 1.2em;  
    color: ${props => props.theme.colors.black};
`;

const StrikeThroughPlanPrice = styled(StyledPlanPrice)`
   text-decoration: line-through;
`;

const StyledDiscountText = styled.div`
  text-align: center;
  line-height: 1.25em;
  font-weight: 400;
  text-transform: uppercase;
  color: ${props => props.theme.colors.orange};
`;

const StyledBillingFrequencyText = styled(StyledPlanTitle)`
    font-size: 1.0em;
    font-weight: 300;
    color: rgba(52, 51, 51, 0.8);
`;

const StyledTrustMeText = styled(StyledPlanTitle)`
    font-size: 1.0em;  
    font-weight: 300;
    color: #989898;
`;

const StyledPlanFeatures = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledPlanFeature = styled.div`
    margin: 0.5em 0;
    display: flex;
    flex-direction: row;
`;

const StyledBullet = styled.div`
    margin-right: 20px;
`;

const StyledFeatureDescription = styled.div`
    width: 200px;
`;

const StyledPlanCard = styled.div<{$isSelected?: boolean}>`
    padding: 20px 0;
    background-color: #FFFCF3;
    border-radius: 25px;
    box-sizing: border-box;
    box-shadow: 5px 5px 5px 2px gray;
    transition: 0.3s;
    margin-bottom: 20px;
    width: 100%;
    border: ${({ $isSelected }) => $isSelected ? "5px solid #EF6236" : "1px solid gray"};
    ${media.greaterThan("medium")`
        width: 275px;
        cursor: pointer;
        margin-bottom: 0;
    `}
`;

const PopularityLabel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start
`

const StyledFaStar = styled(FaStar)`
    margin-top: 3px;
`

interface PlanCardProps {
    className?: string,
    name: Exclude<SelectedPlanType, null>,
    title: string,
    monthlyPriceUSD: number,
    features: string[],
    isSelected: boolean,
    currentUser: AuthState['currentUser']
    onCardClick: (plan: SelectedPlanType) => void,
}

const PlanCard: React.FC<PlanCardProps> = ({ className, isSelected, name, title, monthlyPriceUSD, features, onCardClick, currentUser }) => {
    if (!currentUser) {
      return null
    }

    const isYearly = () => {
      return ['PLATINUM_YEARLY', 'GOLD_YEARLY'].includes(name)
    }

    const isFreeTrialOffered = (process.env.REACT_APP_NEW_USER_FREE_TRIAL === 'true' && !currentUser.stripeSubscriptionId && isYearly())

    return (
        <StyledPlanCard className={className} $isSelected={isSelected} onClick={onCardClick.bind(null, name)}>
            {['PLATINUM_YEARLY', 'PLATINUM'].includes(name) &&
              <PopularityLabel>
                  <StyledFaStar/>
                  <StyledFaStar/>
                  &nbsp;Most Popular&nbsp;
                  <StyledFaStar/>
                  <StyledFaStar/>
              </PopularityLabel>
            }
            <StyledPlanTitle>
                {title}
            </StyledPlanTitle>
            {isFreeTrialOffered ? (
                <>
                  <StrikeThroughPlanPrice>
                    {`$${monthlyPriceUSD}/month`}
                  </StrikeThroughPlanPrice>
                  <StyledBillingFrequencyText>
                    {isYearly() ? 'Billed Annually' : 'Billed Monthly'}
                  </StyledBillingFrequencyText>
                  <StyledDiscountText>
                    Free for the first 7 days
                  </StyledDiscountText>
                  <StyledTrustMeText>
                    Change or cancel anytime
                  </StyledTrustMeText>
                </>
              ) :
              (
                <>
                  <StyledPlanPrice>
                    {`$${monthlyPriceUSD}/month`}
                  </StyledPlanPrice>
                  <StyledBillingFrequencyText>
                    {isYearly() ? 'Billed Annually' : 'Billed Monthly'}
                  </StyledBillingFrequencyText>
                  <StyledTrustMeText>
                      Change or cancel anytime
                  </StyledTrustMeText>
                </>
              )
            }

            <StyledPlanFeatures>
                {features.map((feature: string, index) => (
                    <StyledPlanFeature key={index}>
                        <StyledBullet>✓</StyledBullet>
                        <StyledFeatureDescription>
                            {feature}
                        </StyledFeatureDescription>
                    </StyledPlanFeature>
                ))}
            </StyledPlanFeatures>
        </StyledPlanCard>
    );
};

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.currentUser,
  }
}

export default compose(
  connect(mapStateToProps)
)(PlanCard)