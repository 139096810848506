import amplitude from './amplitude';
import { track } from "@amplitude/analytics-browser";
import { CurrentUser } from '../types/Users';
import { AmplitudeEventProps } from '../types/AmplitudeEventProps';

export const setUserProperties = (currentUser: CurrentUser) => {
  const userProperties = Object.assign({}, currentUser);
  const userId = userProperties.id;
  amplitude.setUserId(userId);
  const identifyObj = new amplitude.Identify()
  for (const [key, value] of Object.entries(userProperties)) {
    identifyObj.set(key, value)
  }
  amplitude.identify(identifyObj)
};

export const recordPageView = (pageName: string) => {
  track(`Viewed ${pageName}`);
};

export const recordClick = (elementName: string, clickEventProps?: AmplitudeEventProps) => {
  if (clickEventProps) {
    track(`Clicked ${elementName}`, clickEventProps);
  } else {
    track(`Clicked ${elementName}`);
  }
};

export const logout = () => {
  amplitude.reset()
};
