import React from "react";
import styled from "styled-components";
import { Redirect } from 'react-router-dom';
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Analytics from './Analytics';
import Onboarding from './Onboarding';
import LoadingBars from "./components/LoadingBars";
import { LoadingCopy } from './common/keys';
import { LoadingState, AuthState } from "./types/States";
import { CurrentUser } from "./types/Users";

const StyledContainer = styled.div`
  background-color: #faf6e8;
`;

interface DashboardProps {
    isBetrayersModalOpen?: boolean
    isToFollowUsersModalOpen?: boolean
    isFollowBackUsersModalOpen?: boolean
    isNonFollowBackUsersModalOpen?: boolean
    isOneWayFollowsModalOpen?: boolean
    isLoading: LoadingState['isLoading']
    currentUser: AuthState['currentUser']
}

const Dashboard: React.FC<DashboardProps> = (props) => {

    const renderLoadingOrRedirect = () => {
        if (props.isLoading) {
            return <LoadingBars/>;
        } else {
            return <Redirect to='/signup' />
        }
    };

    const renderLoadingOrMainContent = (currentUser: CurrentUser) => {
        const canShowDashboard = !!currentUser.subscription && currentUser.isSCAuthed
        const loadingCaption = canShowDashboard ? LoadingCopy : undefined
        if (props.isLoading) {
            return <LoadingBars caption={loadingCaption}/>
        } else {
            if (!currentUser.subscription || !currentUser.isSCAuthed) {
                return (
                    <Onboarding/>
                )
            }
            return (
                <Analytics
                    isBetrayersModalOpen={props.isBetrayersModalOpen}
                    isFollowBackUsersModalOpen={props.isFollowBackUsersModalOpen}
                    isNonFollowBackUsersModalOpen={props.isNonFollowBackUsersModalOpen}
                    isToFollowUsersModalOpen={props.isToFollowUsersModalOpen}
                    isOneWayFollowsModalOpen={props.isOneWayFollowsModalOpen}
                />
            );
        }
    };

    const renderContent = () => {
        if (!props.currentUser) {
            return renderLoadingOrRedirect();
        } else {
            return renderLoadingOrMainContent(props.currentUser);
        }
    };

    return (
        <StyledContainer>
            {renderContent()}
        </StyledContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        shouldDisplayLoadingCaption: state.loading.shouldDisplayCaption,
        currentUser: state.auth.currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(Dashboard);

