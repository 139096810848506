import { AnalyticsState } from '../types/States';
import {
    AnalyticsBetrayersAction,
    AnalyticsFollowBackUsersAction,
    AnalyticsFollowerCountsAction,
    AnalyticsNonFollowBackUsersAction,
    AnalyticsRecommendedSourcesAction,
    AnalyticsJuicySourcesAction,
    AnalyticsToFollowUsersAction,
    AnalyticsIsFollowingEnabledAction,
    AnalyticsUnfollowOneWayFollowsAction,
    AnalyticsAutomaticSchedulingAction,
    AnalyticsOneWayFollowsAction,
    ReduxAction,
} from '../types/ReduxActions';

import {
    ANALYTICS_TO_FOLLOW_USERS,
    ANALYTICS_FOLLOW_BACK_USERS,
    ANALYTICS_NON_FOLLOW_BACK_USERS,
    ANALYTICS_BETRAYERS,
    ANALYTICS_ONE_WAY_FOLLOWS,
    ANALYTICS_RECOMMENDED_SOURCES,
    ANALYTICS_JUICY_SOURCES,
    ANALYTICS_IS_FOLLOWING_ENABLED,
    ANALYTICS_UNFOLLOW_ONE_WAY_FOLLOWS,
    ANALYTICS_AUTOMATIC_SCHEDULING,
    ANALYTICS_FOLLOWER_COUNTS,
} from '../actions/types';

const INITIAL_STATE: AnalyticsState = {
    toFollowUsers: [],
    followBackUsers: [],
    nonFollowBackUsers: [],
    betrayers: [],
    oneWayFollows: {
        loading: false,
        items: [],
    },
    isFollowingEnabled: undefined,
    unfollowOneWayFollows: undefined,
    automaticScheduling: undefined,
    recommendedSources: [],
    juicySources: [],
    followerCounts: [],
};

export default function analyticsReducer(state = INITIAL_STATE, action: ReduxAction) {
    switch(action.type) {
        case ANALYTICS_TO_FOLLOW_USERS: {
            const analyticsToFollowUsersAction: AnalyticsToFollowUsersAction = action;
            return {
                ...state,
                toFollowUsers: analyticsToFollowUsersAction.payload,
            }
        }
        case ANALYTICS_FOLLOW_BACK_USERS: {
            const analyticsFollowBackUsersAction: AnalyticsFollowBackUsersAction = action;
            return {
                ...state,
                followBackUsers: analyticsFollowBackUsersAction.payload,
            }
        }
        case ANALYTICS_NON_FOLLOW_BACK_USERS: {
            const analyticsNonFollowBackUsersAction: AnalyticsNonFollowBackUsersAction = action;
            return {
                ...state,
                nonFollowBackUsers: analyticsNonFollowBackUsersAction.payload,
            }
        }
        case ANALYTICS_BETRAYERS: {
            const analyticsBetrayersAction: AnalyticsBetrayersAction = action;
            return {
                ...state,
                betrayers: analyticsBetrayersAction.payload,
            }
        }
        case ANALYTICS_ONE_WAY_FOLLOWS: {
            const analyticsOneWayFollowsAction: AnalyticsOneWayFollowsAction = action;
            return {
                ...state,
                oneWayFollows: {
                    loading: analyticsOneWayFollowsAction.payload.loading,
                    items: analyticsOneWayFollowsAction.payload.items ?? state.oneWayFollows.items
                },
            }
        }
        case ANALYTICS_RECOMMENDED_SOURCES: {
            const analyticsRecommendedSourcesAction: AnalyticsRecommendedSourcesAction = action;
            return {
                ...state,
                recommendedSources: analyticsRecommendedSourcesAction.payload,
            }
        }
        case ANALYTICS_JUICY_SOURCES: {
            const analyticsJuicySourcesAction: AnalyticsJuicySourcesAction = action;
            return {
                ...state,
                juicySources: analyticsJuicySourcesAction.payload,
            }
        }
        case ANALYTICS_FOLLOWER_COUNTS: {
            const analyticsFollowerCountsAction: AnalyticsFollowerCountsAction = action;
            return {
                ...state,
                followerCounts: analyticsFollowerCountsAction.payload,
            }
        }
        case ANALYTICS_IS_FOLLOWING_ENABLED: {
            const analyticsIsFollowingEnabledAction: AnalyticsIsFollowingEnabledAction = action;
            return {
                ...state,
                isFollowingEnabled: analyticsIsFollowingEnabledAction.payload,
            }
        }
        case ANALYTICS_UNFOLLOW_ONE_WAY_FOLLOWS: {
            const analyticsUnfollowOneWayFollows: AnalyticsUnfollowOneWayFollowsAction = action;
            return {
                ...state,
                unfollowOneWayFollows: analyticsUnfollowOneWayFollows.payload,
            }
        }
        case ANALYTICS_AUTOMATIC_SCHEDULING: {
            const analyticsAutomaticScheduling: AnalyticsAutomaticSchedulingAction = action;
            return {
                ...state,
                automaticScheduling: analyticsAutomaticScheduling.payload,
            }
        }
        default: {
            return state;
        }
    }
}
