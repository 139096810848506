import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AuthState, LoadingState, RootState } from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import media from 'styled-media-query';
import { logEvent } from 'firebase/analytics';
import { analytics } from './common/firebase';
import * as analyticsHelper from './common/analyticsHelper';
import * as keys from './common/keys';
import scConnect from './images/thirdParty/sc-connect.svg'
import { initiateLoginFlow, restartOnboarding } from './actions'

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.2em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
  ${media.greaterThan("medium")`
    font-size: 1.5em;
  `}
`;
const SoundCloudLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    ${media.greaterThan("medium")`
      max-width: 750px;
      margin: auto;
    `}
`;

const StyledByline = styled.div`
    font-size: 0.9em;
    line-height: 1.5em;
    text-align: center;
    ${media.greaterThan("medium")`
        font-size: 1.0em;
    `}
`;

const StyledBoldByline = styled(StyledByline)`
    font-weight: 600;  
    margin-top: 10px;
`;

const AuthContainer = styled.div`
	margin: 100px 0 100px;
`

const StyledButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
`

type SoundCloudLoginProps = {
    initiateLoginFlow: typeof initiateLoginFlow,
    restartOnboarding: typeof restartOnboarding,
    isLoading: LoadingState['isLoading']
    currentUser: AuthState['currentUser'],
}

const SoundCloudLogin: React.FC<SoundCloudLoginProps> = ({ currentUser, isLoading, initiateLoginFlow} ) => {

    useEffect(() => {
        logEvent(analytics, 'ff_page_view', {
            page_title: 'sc_login'
        });
        analyticsHelper.recordPageView(keys.AmpPageSCAuth)
    }, []);

    if (!currentUser) {
        return null
    }

    const  selectedPlan = currentUser.selectedPlan;
    if (!selectedPlan) {
        restartOnboarding(currentUser.id);
    }

    const handleSCAuthButtonClick = () => {
      initiateLoginFlow(currentUser, (redirectUrl) => {
          window.open(redirectUrl, '_self');
      })
		}

    const renderContent = () => {
        if (currentUser.isSCAuthed) {
            return (
                <>
                    <StyledHeading>Log into SoundCloud</StyledHeading>
                    <StyledByline>You have successfully connected your SoundCloud account:</StyledByline>
                    <StyledBoldByline>{currentUser.scUsername}</StyledBoldByline>
                </>
            )
        } else {
            return (
                <>
                    <StyledHeading>Connect with SoundCloud</StyledHeading>
										<AuthContainer>
											<StyledButton
												onClick={handleSCAuthButtonClick}
												disabled={isLoading || currentUser.isSCAuthed }
											>
												<img src={scConnect} alt='SoundCloud Connect'/>
											</StyledButton>
										</AuthContainer>
                </>
            );
        }
    };

    if (!selectedPlan) {
        return (<></>);
    }
    return (
        <SoundCloudLoginContainer>
            {renderContent()}
        </SoundCloudLoginContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        currentUser: state.auth.currentUser,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({ initiateLoginFlow, restartOnboarding }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SoundCloudLogin);
