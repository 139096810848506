import React, { useEffect } from "react";
import ReactPlayer from 'react-player/vimeo'
import styled from "styled-components";
import media from "styled-media-query";
import * as analyticsHelper from './common/analyticsHelper';
import * as keys from './common/keys';

import BlockQuote from "./BlockQuote";
import Button from "./Button";
import Hero from "./Hero";
import campfireImg from "./images/campfire.png";
import highfiveImg from "./images/highfive.png";
import heroImg from "./images/synths.jpg";
import nevermeetImg from "./images/nevermeet.png";
import musicPeopleImg from "./images/musicpeople.png";
import dancePeopleImg from "./images/dancepeople.png";
import { useHistory } from "react-router-dom";
import { AmplitudeEventProps } from './types/AmplitudeEventProps';

const Box = styled.div`
  background-color: ${props => props.theme.colors.beige};

  h1 {
    font-weight: 600;
    margin: 0 0 27px 0;
    font-size: 1.875em;
    color: ${props => props.theme.colors.gray};
    line-height: 1.25em;
    text-align: center;
  }

  display: flex;
  padding: 2em 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${Button} {
    width: 80%;
    ${media.greaterThan("medium")`
      width: 50%;
    `}
  }
`;

const VideoContainer = styled.div`
  position: relative;
  height: 400px;
  flex-basis: 50%;
  ${media.lessThan("large")`
    margin-top: 64px;
  `}
`

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 20px;
  ${media.lessThan("large")`
    flex-direction: column;
    height: 600px;
  `}
  ${media.lessThan("medium")`
    height: unset;
  `}
`

const VideoCaption = styled.div`
  color: ${props => props.theme.colors.black};
  margin: 0 24px;
`

const HeroText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


const Testimonials = styled.div`
  margin: 0 auto 4em auto;
  max-width: 1020px;

  > div:first-child {
    display: flex;
    margin-bottom: 2em;

    margin: auto;
    flex-direction: column;

    ${media.greaterThan("medium")`
      flex-direction: row;
      width: 82%;
      margin-top: 2em;
    `}
  }

  img {
    display: block;
    margin: auto;
    width: 70%;

    ${media.greaterThan("medium")`
      width: 31%;
    `}
  }
`;

const TeaExplainers = styled.div`
  img {
    display: block;
    max-width: 100%;
    ${media.greaterThan("medium")`
      width: 50%;
    `}
  }

  > h1 {
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
    color: ${props => props.theme.colors.black};
    font-size: 1em;
    font-weight: 600;
    padding: 0 16px;
  }

  h2 {
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.orange};
    margin-bottom: 18px;
    font-weight: 600;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 82%;
    margin: 2em auto;

    ${media.greaterThan("medium")`
    flex-direction: row;

    > * {
      :nth-child(1) {
          flex: 1;
          align-self: center;
          margin-right: 3%;      
      }

      :nth-child(2) {
        flex: 1;
      }
    }

    :nth-child(3) {
      flex-direction: row-reverse;
      > * {
        :nth-child(1) {
          margin: 0 0 0 3%;
        }
      }
    }
    `}
    }

    max-width: 1020px;
    margin: 0 auto 3em;
  }
`;

const StrangerLetter = styled.div`
  max-width: 1020px;
  width: 82%;
  margin: 0 auto 4em auto;

  p {
    margin: 0 0 0.8125em 0;
  }

  img {
    margin: 0 0 1.5em 0;
    width: 75%;
    display: block;
    align-self: center;

    ${media.greaterThan("medium")`
      width: 43%;
    `}
  }
  h1 {
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      flex: 1;
    }

    ${media.greaterThan("medium")`
      flex-direction: row;

    `}
  }
`;

const TeaTimeBasics = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.beige};
  padding: 2em 1em;
  justify-content: center;
  text-align: center;
  margin-bottom: 3em;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 9px 0;
    font-size: 1.5em;
    color: ${props => props.theme.colors.orange};
    font-weight: 600;
    white-space: normal;
    line-height: 1.2em;
  }

  p {
    margin: 0;
    color: ${props => props.theme.colors.black};
    font-size: 18px;
    line-height: 1.625em;
  }

  > div {
    margin: 0 0 36px 0;
    flex-shrink: 1.4;
    :first-child {
        flex-shrink: unset;
    }
    :last-child {
      margin: 0;
    }

    ${media.greaterThan("medium")`
        margin: 0 2.35765% 0 0;
      `}
  }

  ${media.greaterThan("medium")`
    flex-direction: row;

  `}
`;

const Root: React.FC = () => {
    useEffect(() => {
        analyticsHelper.recordPageView(keys.AmpPageHome);
        window.scrollTo(0, 0);
    }, []);
    const history = useHistory();
    const handleSignUpButtonClick = (ctaText: string) => {
        const ampEventProps: AmplitudeEventProps = {
            [keys.AmpPropButtonCTA]: ctaText,
        };
        analyticsHelper.recordClick(keys.AmpElementSignupCTA, ampEventProps);
        history.push('/signup')
    };
    return (
        <>
            <Hero backgroundUrl={heroImg}>
                <HorizontalContainer>
                    <HeroText>
                        <h1>Focus on making music</h1>
                        <p>We'll take care of the rest</p>
                        <Button onClick={() => handleSignUpButtonClick( 'Get discovered' )}>Get started</Button>
                    </HeroText>
                    <VideoContainer>
                        <ReactPlayer
                            url='https://player.vimeo.com/video/835722151'
                            controls={true}
                            height={'100%'}
                            width={'100%'}
                            config={{
                                playerOptions: {
                                    autopause: 'true',
                                    background: 'false',
                                },
                            }}
                        />
                        <VideoCaption>See what real artists have to say about Artist Management</VideoCaption>
                    </VideoContainer>
                </HorizontalContainer>
            </Hero>

            <TeaTimeBasics>
                <div>
                    <h2>Give your music more exposure</h2>
                    <p>Our sophisticated algorithms find active SoundCloud listeners who have already shown interest in similar music.</p>
                </div>

                <div>
                    <h2>Get organic comments and reposts</h2>
                    <p>You define how your account engages with new listeners.
                        These are real people who are more likely to truly enjoy your music.
                    </p>
                </div>

                <div>
                    <h2>Watch your fanbase grow</h2>
                    <p>Our algorithm reaches an average of 1200 active listeners per month who have not discovered your music yet.</p>
                </div>
            </TeaTimeBasics>

            <TeaExplainers>
                <h1>so why do so many artists use Artist Management?</h1>

                <section>
                    <img src={musicPeopleImg} alt="" />
                    <div>
                        <h2>Promoting your music can be draining.</h2>
                        <p>
                            There are thousands of talented undiscovered artists that share their work on SoundCloud.
                            We know that you’d prefer to spend your time doing what you do best, rather than
                            using up your mental space to find ways to promote your music, which could easily snowball into a full-time job.
                            Ugh.
                        </p>
                    </div>
                </section>

                <section>
                    <img src={nevermeetImg} alt="" />
                    <div>
                        <h2>You reach the listeners you wouldn't reach otherwise.</h2>

                        <p>
                            In all likelihood, until you are signed with a prominent label, active listeners within
                            your genre do not usually stumble upon your music very easily. Thanks to our sophisticated algorithms,
                            Artist Management makes your music discoverable and accessible to real humans who are more likely to
                            actually appreciate your music. They often end up liking, reposting, and commenting on your tracks
                            for no reason other than the fact that they genuinely enjoy your music.
                        </p>
                    </div>
                </section>

                <section>
                    <img src={highfiveImg} alt="" />
                    <div>
                        <h2>Your hands are made for high fiving!</h2>
                        <p>
                            And…for playing your guitar. Artist Management helps hard-working musicians enjoy the organic
                            growth that they deserve, while allowing listeners to discover new artists. It's a win-win
                            for everyone.
                        </p>
                    </div>
                </section>
            </TeaExplainers>

            <Box>
                <h1>Get organic streams, likes, and reposts</h1>
                <Button onClick={() => handleSignUpButtonClick( 'Get started' )}>Get started</Button>
            </Box>

            <Testimonials>
                <div>
                    <BlockQuote>
                        ”I've tried a number of different products in this space and I have to say that Artist Management is by far the most effective. I got my
                        first 1000 followers within eight months of using it. Before using Artist Management, I used to message listeners and ask them
                        to follow me. While that worked sometimes, it took way too much time and energy.
                        This site simplifies a lot and puts music promotion pretty much on auto-pilot.”
                        <span> Karsten Weber, Berlin, Germany</span>
                    </BlockQuote>

                    <BlockQuote>
                        ”Really glad I signed up!
                        The strategy makes a lot of sense and I can see why it works. After releasing a track, you need to get
                        it in front of people so they can actually listen to it. Labels that would previously ignore me,
                        now take me seriously as a result of the traction I am getting on SoundCloud.”
                        <span> Matt Glass, California, USA</span>
                    </BlockQuote>
                </div>
                <div>
                    <img src={dancePeopleImg} width="48vw" alt="" />
                </div>
            </Testimonials>

            <StrangerLetter>
                <h1>New listeners await your music</h1>
                <div>
                    <img src={campfireImg} alt="" />
                    <div>
                        <p>
                            A significant number of SoundCloud users are listeners and music curators. We at Artist Management
                            are tired of listening to Justin Bieber and Carly Rae Jepsen songs on the radio, but sometimes we
                            don’t have a choice. And we know this sentiment is shared among the masses.
                            That’s where your music comes in.
                        </p>
                        <p>
                            Our goal is to build you a community of listeners who love your music and would play it for
                            their friends.
                        </p>
                    </div>
                </div>
            </StrangerLetter>
            <Box>
                <h1>Are you ready to grow your fanbase?</h1>
                <Button onClick={() => handleSignUpButtonClick( 'Yes' )}>Yes</Button>
            </Box>
        </>
    );
};

export default Root;
