import React from 'react';
import { createRoot } from 'react-dom/client';
import * as keys from './common/keys';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { CookiesProvider } from 'react-cookie';
import reducers from './reducers';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const stripePromise = loadStripe(keys.StripePublishableKey);
const store = createStore(
    reducers,
    {},
    applyMiddleware(reduxThunk),
);
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Provider store={store}>
          <Elements stripe={stripePromise}>
              <App />
          </Elements>
      </Provider>
    </CookiesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
