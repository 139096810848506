import {LoadingState, RootState} from './types/States'
import React, {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as analyticsHelper from './common/analyticsHelper'
import * as keys from './common/keys'
import { validateViewingToken } from './actions'
import {bindActionCreators, compose, Dispatch} from 'redux'
import {connect} from 'react-redux'
import LoadingBars from './components/LoadingBars'
import ReactPlayer, { WistiaConfig } from 'react-player/wistia'
import styled from 'styled-components'
import media from 'styled-media-query'
import { StemMasteringWorkshopDateString } from './common/keys'


type StemMasteringWorkshopReplayProps = {
  isLoading: LoadingState["isLoading"],
  validateViewingToken: typeof validateViewingToken,
}

const wistiaConfig: WistiaConfig = {
  options: {
    fullscreenButton: true,
    playbar: true,
    playerColor: '433417',
    wmode: 'transparent',
  }
}

const VideoContainer = styled.div`
  position: relative;
  height: 400px;
  width: 100%;  
  flex-basis: 50%;
  background-color: #4334170F;
  ${media.lessThan("medium")`
    width: 100%
    height: 100%;
  `}
`

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledText = styled.p`
  margin: 0 0 18px 0;
`;

const StemMasteringWorkshopReplay: FC<StemMasteringWorkshopReplayProps> = (props) => {
  const params = useParams<{token?: string}>()
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null)
  const { validateViewingToken } = props

  useEffect(() => {
    if (params.token) {
      validateViewingToken(params.token, (error) => {
        if (error) {
          setIsAuthorized(false)
        } else {
          setIsAuthorized(true)
        }
      })
    }
  }, [params.token, isAuthorized, validateViewingToken])

  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageStemMasteringWorkshopReplay);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const wLogo = document.querySelectorAll('[data-handle="wistiaLogo"]')[0]
      if (wLogo) {
        (wLogo as HTMLElement).style.visibility = 'hidden'
      }
    }, 1000)
  }, []);

  if (props.isLoading) {
    return (
      <LoadingBars caption='Verifying the validity of your link to watch the replay. Hang tight...'/>
    )
  }

  if (!isAuthorized) {
    return <div>
      Nothing to see.
    </div>
  }

  return (
    <StyledContainer>
      <StyledHeading>Stem Mastering Workshop</StyledHeading>
      <StyledText>{StemMasteringWorkshopDateString}</StyledText>
      <VideoContainer>
        <ReactPlayer
          url='https://fast.wistia.com/embed/symrbau8mp'
          config={wistiaConfig}
          height={'100%'}
          width={'100%'}
        />
      </VideoContainer>
    </StyledContainer>

  )
}

function mapStateToProps(state: RootState) {
  return {
    isLoading: state.loading.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators( {
    validateViewingToken,
  }, dispatch )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(StemMasteringWorkshopReplay)
