import React, { FC, ChangeEvent, FormEvent, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import media from "styled-media-query";

import Button from "./Button";
import Input from "./Input";
import { LoadingState, RootState } from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { resetPassword } from './actions';
import LoadingBars from "./components/LoadingBars";

const StyledHeading = styled.h2`
  line-height: 1.375em;
  font-family: Gibson;
  font-weight: 600;
  font-size: 27px;
  color: ${props => props.theme.colors.orange};
  padding: 1em 1em 0.75em 0;
  margin: 0;
`;

const StyledText = styled.p`
  line-height: 1.25em;
  font-family: Gibson;
  font-size: 18px;
  color: #404040;
  padding: 0 0 1em 0;
  margin: 0;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family: Gibson;
  color: ${props => props.theme.colors.orange};
  line-height: 0.75em;
  margin: 0 0 0.8125em 0;
`;

const StyledContainer = styled.div`
  color: #404040;
  height: 75vh;
  font-size: 16px;
  background-color: #faf6e8;
`;

const StyledForm = styled.form`
  max-width: 790px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  font-size: inherit;

  ${Button} {
    ${media.greaterThan("medium")`
      align-self: end;
    `}
  }
`;

type PasswordEditProps = {
  isLoading: LoadingState['isLoading']
  resetPassword: typeof resetPassword,
};

const PasswordEdit: FC<PasswordEditProps> = (props) => {
  const { token } = useParams<{token: string}>();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [newPasswordValidationMessage, setNewPasswordValidationMessage] = useState('* You can\'t set an empty password. That would be too easy to guess!');
  const [shouldShowNewPasswordValidationMessage, setShouldShowNewPasswordValidationMessage] = useState(false);

  const [passwordConfirmationValidationMessage, setPasswordConfirmationValidationMessage] = useState('* Passwords don\'t match.');
  const [shouldShowPasswordConfirmationValidationMessage, setShouldShowPasswordConfirmationValidationMessage] = useState(false);

  const handleNewPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(evt.target.value);
    if (!evt.target.value) {
      setNewPasswordValidationMessage('* You can\'t set an empty password. That would be too easy to guess!');
    } else if (evt.target.value.length < 8) {
      setNewPasswordValidationMessage('* Your new password must be at least 8 characters long.');
    }
    else {
      setNewPasswordValidationMessage('');
      setShouldShowNewPasswordValidationMessage(false);
    }
  };

  const handlePasswordConfirmationChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(evt.target.value);
    if (evt.target.value !== newPassword) {
      setPasswordConfirmationValidationMessage('* Passwords don\'t match.');
    } else {
      setPasswordConfirmationValidationMessage('');
      setShouldShowPasswordConfirmationValidationMessage(false);
    }
  };

  const inputsAreValid = () => {
    const isNewPasswordValid = newPassword.length >= 8;
    const isPasswordConfirmationValid = passwordConfirmation === newPassword;

    if (!isNewPasswordValid) {
      setShouldShowNewPasswordValidationMessage(true);
    }

    if (!isPasswordConfirmationValid) {
      setShouldShowPasswordConfirmationValidationMessage(true);
    }

    return isNewPasswordValid && isPasswordConfirmationValid;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (inputsAreValid()) {

      props.resetPassword({
        password: newPassword,
        token,
      }, (error: Error | undefined) => {
          setNewPassword('');
          setPasswordConfirmation('');
          if (error) {
            history.push('/users/password/new');
          } else {
            history.push('/signin')
          }
      });
    }
  };

  const renderMainContent = () => {
    if (props.isLoading) {
      return (
        <LoadingBars/>
      )
    } else {
      return (
        <StyledForm onSubmit={handleSubmit}>
          <StyledHeading>Reset your password</StyledHeading>
          <StyledText>
            Enter your new password twice for good luck and you're all set!
          </StyledText>
          <Input
            type="password"
            value={newPassword}
            onChange={handleNewPasswordChange}
            placeholder="New password"
          />
          {
            shouldShowNewPasswordValidationMessage &&
              <StyledInputValidationText>
                {newPasswordValidationMessage}
              </StyledInputValidationText>
          }
          <Input
            type="password"
            value={passwordConfirmation}
            onChange={handlePasswordConfirmationChange}
            placeholder="One more time!"
          />
          {
            shouldShowPasswordConfirmationValidationMessage &&
              <StyledInputValidationText>
                {passwordConfirmationValidationMessage}
              </StyledInputValidationText>
          }

          <Button
            disabled={props.isLoading}
          >
            reset password
          </Button>
        </StyledForm>
      )
    }
  }

  return (
    <StyledContainer>
      {renderMainContent()}
    </StyledContainer>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isLoading: state.loading.isLoading,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ resetPassword }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(PasswordEdit)
