import React, { ChangeEvent, FormEvent, useState } from 'react';
import styled from 'styled-components';
import { AuthState, LoadingState, RootState } from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as keys from './common/keys';
import Button from './Button';
import media, { defaultBreakpoints } from 'styled-media-query';
import infoIconImg from './images/icons/info-icon.svg';
import { logEvent } from 'firebase/analytics';
import { analytics } from './common/firebase';
import { useMediaQuery } from 'react-responsive';
import Tooltip from "@mui/material/Tooltip";
import { submitSourceFollowersRequest } from './actions'

const StyledTitleContainer = styled.div`
  width: calc(100vw - 40px);
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledIconImg = styled.img`
    position: relative;
    top: -2px;
    width: 18px;
    height: 18px;
`;

const StyledIconContainer = styled.div`
    display: flex;
    margin-left: 10px;
    cursor: pointer;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family Gibson;
  color: ${props => props.theme.colors.orange};
  line-height: 18px;
  margin: 0.8125em 0 0.8125em 0;
  width: calc(100vw - 40px);
  ${media.greaterThan("medium")`
      width: 600px;
      margin: 10px auto 25px;
  `}
`;

const StyledNote = styled.div`
  box-sizing: border-box;
  display: block;
  width: calc(100vw - 40px);
  padding-left: 1em;
  font-size: 16px;
  ${media.greaterThan("medium")`
      width: 600px;
      margin: 0 auto 0;
      font-size: 18px;
  `}
`

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0 0;
  padding: 1em;
  width: calc(100vw - 40px);
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
  &:disabled {
    color: gray;
  }
  ${media.greaterThan("medium")`
      width: 600px;
      margin: 0 auto 0;
  `}
`;

const StyledButton = styled(Button)`
  display: block;
  width: calc(100vw - 40px);
  box-sizing: border-box;
  text-align: center;
  margin: 0.8124em 0 0.3em 0;
  white-space: nowrap;
  ${media.greaterThan("medium")`
        box-sizing: content-box;
        width: auto;
        margin: 20px auto 0.3em;
        text-align: center;
  `}
`;

type SourceFollowersFormProps = {
    isDisabled: boolean,
    disabledButtonText?: string,
    onInfoIconClick: () => void,
    currentUser: AuthState['currentUser'],
    isLoading: LoadingState['isLoading'],
    submitSourceFollowersRequest: typeof submitSourceFollowersRequest,
};

const SourceFollowersForm: React.FC<SourceFollowersFormProps> = (props) => {
    const isDesktop = useMediaQuery({
        minWidth: defaultBreakpoints.medium
    });
    const placeholderText = isDesktop ? 'SoundCloud URL of Track or Artist with music similar to yours' : 'SoundCloud URL of Track or Artist';
    const [url, setUrl] = useState('');
    const [shouldShowUrlValidationMessage, setShouldShowUrlValidationMessage] = useState(false);
    const [urlValidationMessage, setUrlValidationMessage] = useState('* URL cannot be blank.');


    const { currentUser, submitSourceFollowersRequest } = props

    if (!currentUser) {
        return null
    }

    const handleUrlChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setUrl(evt.target.value);
        if (!evt.target.value) {
            setUrlValidationMessage('* URL cannot be blank.');
        } else {
            const urlType = testUrlFormat(evt.target.value);
            if (!urlType) {
                setUrlValidationMessage('* The URL is not a properly formatted artist or track URL.')
            } else {
                setUrlValidationMessage('');
                setShouldShowUrlValidationMessage(false);
            }
        }
    };

    const testUrlFormat = (url: string): string | null => {
        if(/^(http[s]?:\/)?\/?(m.)?soundcloud.com\/([\w\-.]+)\/([\w\-.]+)\/?(?:\?|$)/.test(url)) {
            return keys.urlTypeTrack;
        }
        if (/^(http[s]?:\/)?\/?(m.)?soundcloud.com\/([\w\-.]+)\/?(?:\?|$)/.test(url)) {
            return keys.urlTypeUser;
        }

        if (/^(http[s]?:\/)?\/?on.soundcloud.com\/([\w\-.]+)\/?(?:\?|$)/.test(url)) {
            return keys.urlTypeOnSoundCloud;
        }
        return null;
    };

    const validateUrl = (): string | null => {
        const urlType = testUrlFormat(url);

        if (!urlType) {
            setShouldShowUrlValidationMessage(true);
        }

        return urlType;
    };

    const handleSubmit = async (evt: FormEvent) => {
        evt.preventDefault();
        const urlType = validateUrl();
        if (urlType) {
            let finalURL: string;
            if (url.startsWith('http')) {
                finalURL = url;
            } else {
                finalURL = 'https://' + url;
            }

            if (finalURL.indexOf('m.') !== -1) {
                finalURL = finalURL.replace('m.', '')
            }

            submitSourceFollowersRequest(currentUser, finalURL);
            logEvent(analytics, 'ff_button_click', {
                name: 'source_followers_submit'
            });
        }
    };

    return (
        <>
            <StyledTitleContainer>
                <div>
                    Schedule Follows
                </div>
                { props.currentUser && props.currentUser.selectedPlan !== 'INTRO' &&
                <Tooltip title='Click here for an overview of how Artist Management works' arrow>
                    <StyledIconContainer onClick={props.onInfoIconClick}>
                        <StyledIconImg src={infoIconImg} alt="" />
                    </StyledIconContainer>
                </Tooltip>
                }
            </StyledTitleContainer>
            <form onSubmit={handleSubmit}>
                <StyledInput type="text" value={url} onChange={handleUrlChange} placeholder={placeholderText} disabled={props.isDisabled}/>
                <StyledNote>*You can use the same URL more than once</StyledNote>
                { shouldShowUrlValidationMessage &&
                <StyledInputValidationText>
                    { urlValidationMessage }
                </StyledInputValidationText>
                }
                <StyledButton
                    disabled={props.isLoading || props.isDisabled}
                >
                    {props.isDisabled ? props.disabledButtonText ?? 'Plan Limit Reached' : 'Submit'}
                </StyledButton>
            </form>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        currentUser: state.auth.currentUser,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        submitSourceFollowersRequest,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SourceFollowersForm);
