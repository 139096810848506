import {LoadingState, RootState} from './types/States'
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import * as analyticsHelper from './common/analyticsHelper'
import * as keys from './common/keys'
import { requestStemMasteringWorkshopReplay } from './actions'
import { bindActionCreators, compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import media from 'styled-media-query'
import Button from './Button'
import {useCookies} from 'react-cookie'
import LoadingBars from './components/LoadingBars'
import {StemMasteringWorkshopDateString} from './common/keys'


type StemMasteringWorkshopFormProps = {
  isLoading: LoadingState["isLoading"],
  requestStemMasteringWorkshopReplay: typeof requestStemMasteringWorkshopReplay,
}

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  max-width: 1020px;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const StyledForm = styled.form`
  ${media.greaterThan("medium")`
    max-width: 82%;
  `}
  ${media.greaterThan("large")`
    max-width: 65%;
  `}
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledText = styled.p`
  margin: 0 0 18px 0;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0.8125em 0;
  padding: 1em;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
  background-color: white;
  min-width: 0;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family: Gibson;
  color: ${props => props.theme.colors.orange};
  line-height: 1em;
  margin: 0 0 0.8125em 0;
`;

const StyledButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  width: 100%;
`;

const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000

const StemMasteringWorkshopForm: FC<StemMasteringWorkshopFormProps> = (props) => {
  const history = useHistory();
  const [, setCookie] = useCookies(['am_l_id']);

  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageStemMasteringWorkshopForm);
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [nameValidationMessage, setNameValidationMessage] = useState('* You must tell us who you are. Don\'t be shy!.');
  const [shouldShowNameValidationMessage, setShouldShowNameValidationMessage] = useState(false);

  const [emailValidationMessage, setEmailValidationMessage] = useState('* Email cannot be blank!');
  const [shouldShowEmailValidationMessage, setShouldShowEmailValidationMessage] = useState(false);

  const handleNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setName(evt.target.value);
    if (!evt.target.value) {
      setNameValidationMessage('* You must tell us who you are. Don\'t be shy!');
    } else {
      setNameValidationMessage('');
      setShouldShowNameValidationMessage(false);
    }
  };

  const handleEmailChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
    if (!evt.target.value) {
      setEmailValidationMessage('* Email cannot be blank!');
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(evt.target.value)) {
      setEmailValidationMessage('* We need a valid email address in order to send you the workshop replay.');
    }
    else {
      setEmailValidationMessage('');
      setShouldShowEmailValidationMessage(false);
    }
  };

  const inputsAreValid = () => {
    const isNameValid = name;
    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

    if (!isNameValid) {
      setShouldShowNameValidationMessage(true);
    }
    if (!isEmailValid) {
      setShouldShowEmailValidationMessage(true);
    }

    return isNameValid && isEmailValid;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    analyticsHelper.recordClick(keys.AmpElementStemMasteringReplaySubmitButton);
    if (inputsAreValid()) {
      props.requestStemMasteringWorkshopReplay({
        firstName: name,
        email,
      }, (leadId) => {
        setCookie('am_l_id', leadId, { expires: new Date(Date.now() + ONE_MONTH_IN_MS)})
        history.push('/stem-mastering/thank-you');
      });
    }
  };

  if (props.isLoading) {
    return (
      <LoadingBars caption='Verifying your information to send you the replay. Hang tight...'/>
    )
  }

  return (
    <StyledContainer>
      <StyledContent>
        <StyledForm onSubmit={handleSubmit}>
          <StyledHeading>Access the replay of our audio mastering workshop from {StemMasteringWorkshopDateString}</StyledHeading>
          <StyledText>
            Unlock the secrets to professionally mastering your music. By the end of this 1-hour workshop you will have gained:
            <ul>
              <li>An solid understanding gain-staging and its importance in the mastering process</li>
              <li>An in-depth breakdown of our mastering chain (including which plugins we use) </li>
              <li>A first-hand look at referencing and its role in mastering</li>
            </ul>
          </StyledText>
          <StyledText>
            We recommend listening with studio monitors or quality headphones.
          </StyledText>
          <StyledInput
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="First name"
          />
          { shouldShowNameValidationMessage &&
              <StyledInputValidationText>
                {nameValidationMessage}
              </StyledInputValidationText>
          }
          <StyledInput
            type="text"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email address"
          />
          { shouldShowEmailValidationMessage &&
              <StyledInputValidationText>
                {emailValidationMessage}
              </StyledInputValidationText>
          }
          <StyledButton
            disabled={props.isLoading}
          >
            send me the replay
          </StyledButton>
        </StyledForm>
      </StyledContent>
    </StyledContainer>
  )
}

function mapStateToProps(state: RootState) {
  return {
    isLoading: state.loading.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators( {
    requestStemMasteringWorkshopReplay,
  }, dispatch )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(StemMasteringWorkshopForm)
