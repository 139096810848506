import React from 'react';
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FlashMessage from './FlashMessage';

const ErrorMessage = styled(FlashMessage)`
  background-color: ${props => props.theme.colors.orange};
`;
const SuccessMessage = styled(FlashMessage)`
  background-color: #66C4A4;
`;

const FlashContainer: React.FC = (props: any) => {
  return (
    <>
      { props.errorMessage &&
          <ErrorMessage>
            {props.errorMessage}
          </ErrorMessage>
      }
      { props.successMessage &&
      <SuccessMessage>
        {props.successMessage}
      </SuccessMessage>
      }
    </>
  );

};

function mapStateToProps(state: RootState) {
  return {
    errorMessage: state.flash.errorMessage,
    successMessage: state.flash.successMessage,
  }
}

export default compose(
  connect(mapStateToProps)
)(FlashContainer);
