import styled from 'styled-components';

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0.8125em 0;
  padding: 1em;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
  background-color: white;
  min-width: 0px;
`;

export default StyledInput;
