import React, { ChangeEvent, FormEvent, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Button from "./Button";
import Input from "./Input";
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';
import LoadingBars from "./components/LoadingBars";

const StyledHeading = styled.h2`
  font-size: 1.375em;
  line-height: 1.375em;
  font-family: Gibson;
  font-weight: 600;
  font-size: 27px;

  color: ${props => props.theme.colors.orange};
  padding: 1em 1em 0.75em 0;
  margin: 0;
`;

const StyledText = styled.p`
  font-size: 0.9em;
  line-height: 1.25em;
  font-family: Gibson;
  font-size: 18px;
  color: #404040;
  padding: 0 0 1em 0;
  margin: 0;
`;

const StyledContainer = styled.div`
  color: #404040;
  height: 75vh;
  font-size: 16px;
  background-color: #faf6e8;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family Gibson;
  color: ${props => props.theme.colors.orange};
  line-height: 0.75em;
  margin: 0 0 0.8125em 0;
`;

const StyledForm = styled.form`
  max-width: 790px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  font-size: inherit;

  ${Button} {
    ${media.greaterThan("medium")`
      align-self: end;
    `}
  }
`;

const PasswordNewForm: React.FC = (props: any) => {
  const [email, setEmail] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] = useState('* Email cannot be blank!');
  const [shouldShowEmailValidationMessage, setShouldShowEmailValidationMessage] = useState(false);

  const handleEmailChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
    if (!evt.target.value) {
      setEmailValidationMessage('* Email cannot be blank!');
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(evt.target.value)) {
      setEmailValidationMessage('* The email is not properly formatted.');
    }
    else {
      setEmailValidationMessage('');
      setShouldShowEmailValidationMessage(false);
    }
  };

  const inputsAreValid = () => {
    const isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

    if (!isEmailValid) {
      setShouldShowEmailValidationMessage(true);
    }

    return isEmailValid;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (inputsAreValid()) {

      props.requestPasswordResetEmail(email, () => {
        setEmail('');
      });
    }
  };

  const renderMainContent = () => {
    if (props.isLoading) {
      return (
        <LoadingBars/>
      )
    } else {
      return (
        <StyledForm onSubmit={handleSubmit}>
          <StyledHeading>Forgot your password?</StyledHeading>

          <StyledText>
            Enter the email that you signed up with, and we'll send you
            instructions to reset your password.
          </StyledText>

          <Input
            type="text"
            placeholder="Email Address"
            onChange={handleEmailChange}
            value={email}
          />
          { shouldShowEmailValidationMessage &&
              <StyledInputValidationText>
                {emailValidationMessage}
              </StyledInputValidationText>
          }

          <Button
            disabled={props.isLoading}
          >
            send reset link
          </Button>
        </StyledForm>
      )
    }
  }

  return (
      <StyledContainer>
        {renderMainContent()}
      </StyledContainer>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isLoading: state.loading.isLoading,
  }
}

export default compose(
    connect(mapStateToProps, actions)
)(PasswordNewForm);
