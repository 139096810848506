import styled from "styled-components";
import media from "styled-media-query";

const BlockQuote = styled.blockquote`
  padding: 0.5em 3em 0.5em 0;
  border-right: 1em solid #fcebc7;
  border-left: 0;
  font-style: normal;
  font-size: 16.2px;
  color: ${props => props.theme.colors.black};

  span {
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
  }

  ${media.greaterThan("medium")`
    margin: 0 2.84259% 1.625em 0;
  `}
`;

export default BlockQuote;
