import React from "react";
import styled from "styled-components";
import { Redirect } from 'react-router-dom';
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';
import loadingBarsImg from './images/loading-bars.svg';
import media from 'styled-media-query';
import { LoadingCopy } from './common/keys';

const StyledContainer = styled.div`
  background-color: #faf6e8;
`;

const LoadingBarsContainer = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3em 1em;
`;

const LoadingBarsImage = styled.img`
    width: 20%;
    height: 20%;
    fill: red;
`;

const LoadingBarsCaption = styled.div`
    margin: 20px 40px;
    text-align: center;
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
    ${media.greaterThan("medium")`
      font-size: 20px;
    `}
`;


const AuthedWrapper: React.FC = (props: any) => {

    const renderLoadingOrRedirect = () => {
        if (props.isLoading) {
            return LoadingBar();
        }
        else if (!props.currentUser) {
            return <Redirect to='/signup' />
        } else if (!props.currentUser.subscription) {
            return <Redirect to='/dashboard' />
        }
    };

    const renderLoadingOrMainContent = () => {
        if (props.isLoading) {
            return LoadingBar();
        } else {
            return (
                props.children
            );
        }
    };

    const LoadingBar = () => {
        return (
            <LoadingBarsContainer>
                <LoadingBarsImage src={loadingBarsImg} alt="loading-bars"/>
                {props.shouldDisplayLoadingCaption &&
                <LoadingBarsCaption>
                    {LoadingCopy}
                </LoadingBarsCaption>
                }
            </LoadingBarsContainer>
        );
    };

    const renderContent = () => {
        if (!props.currentUser) {
            return renderLoadingOrRedirect();
        } else {
            return renderLoadingOrMainContent();
        }
    };

    return (
        <StyledContainer>
            {renderContent()}
        </StyledContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        shouldDisplayLoadingCaption: state.loading.shouldDisplayCaption,
        currentUser: state.auth.currentUser,
    }
}

export default compose(
    connect(mapStateToProps, actions)
)(AuthedWrapper);

