import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import styled from "styled-components";
import { Redirect } from 'react-router-dom';
import { AuthState, LoadingState, RootState } from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import loadingBarsImg from './images/loading-bars.svg';
import media from 'styled-media-query';
import { attemptSCAuthorization } from './actions'

const StyledContainer = styled.div`
  background-color: #faf6e8;
`;

const LoadingBarsContainer = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LoadingBarsImage = styled.img`
    width: 20%;
    height: 20%;
    fill: red;
`;

const LoadingBarsCaption = styled.div`
    margin: 20px 40px;
    text-align: center;
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
    ${media.greaterThan("medium")`
      font-size: 20px;
    `}
`;

type CallbackProps = {
	isLoading: LoadingState['isLoading'],
	shouldDisplayLoadingCaption: LoadingState['shouldDisplayCaption'],
	currentUser: AuthState['currentUser'],
	attemptSCAuthorization: typeof attemptSCAuthorization,
}

const Callback: React.FC<CallbackProps> = (props) => {
	const location = useLocation()
	const history = useHistory()
	const [authAttempted, setAuthAttempted] = useState<boolean>(false)
	const [code, setCode] = useState<string | null>(null);
	const [oauthState, setOauthState] = useState<string | undefined>(undefined)

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)

		// state needs to be first because it must be included in the request only if present
		if (queryParams.has('state')) {
			const currentOauthState = queryParams.get('state')
			setOauthState(currentOauthState ?? undefined)
			queryParams.delete('state')

			// JUST FOR DEBUGGING PURPOSES
			// if (currentOauthState && queryParams.has('code')) {
			// 	console.log('code', queryParams.get('code'))
			// }
		}

		if (queryParams.has('code')) {
			const currentCode = queryParams.get('code')
			setCode(currentCode)
			queryParams.delete('code')
		}

		if (queryParams.has('authentication_method')) {
			queryParams.delete('authentication_method')
		}

		if (queryParams.has('signed_up')) {
			queryParams.delete('signed_up')
		}

		history.replace({
			search: queryParams.toString(),
		});

	}, [history, location.search])

	const { isLoading, currentUser, attemptSCAuthorization, shouldDisplayLoadingCaption } = props;
	useEffect(() => {
		if (!isLoading && currentUser && code) {
			attemptSCAuthorization(currentUser, code, oauthState);
			setCode(null);
			setOauthState(undefined)
			setAuthAttempted(true)
		}
	}, [code, oauthState, attemptSCAuthorization, isLoading, currentUser])

	const renderLoadingOrRedirect = () => {
		if (isLoading) {
			return LoadingBar();
		} else {
			return <Redirect to='/signin' />
		}
	};

	const renderMainContentOrRedirect = () => {
		if ((currentUser && currentUser.isSCAuthed) || authAttempted) {
			return <Redirect to='/dashboard' />
		} else {
			return LoadingBar()
		}
	}

	const LoadingBar = () => {
		return (
			<LoadingBarsContainer>
				<LoadingBarsImage src={loadingBarsImg} alt="loading-bars"/>
				{shouldDisplayLoadingCaption &&
					<LoadingBarsCaption>
            You will be automatically redirected in a few seconds.
					</LoadingBarsCaption>
				}
			</LoadingBarsContainer>
		);
	};

	const renderContent = () => {
		if (!currentUser) {
			return renderLoadingOrRedirect();
		} else {
			return renderMainContentOrRedirect();
		}
	};

	return (
		<StyledContainer>
			{renderContent()}
		</StyledContainer>
	);
};

function mapStateToProps(state: RootState) {
	return {
		isLoading: state.loading.isLoading,
		shouldDisplayLoadingCaption: state.loading.shouldDisplayCaption,
		currentUser: state.auth.currentUser,
	}
}

function mapDispatchToProps(dispatch: Dispatch) {
	return bindActionCreators({
		attemptSCAuthorization,
	}, dispatch)
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps)
)(Callback);

