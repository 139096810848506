import React, { FC, ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import Button from "./Button";
import {AuthState, LoadingState, RootState} from './types/States';
import {bindActionCreators, compose, Dispatch} from 'redux';
import { connect } from 'react-redux';
import { signin } from './actions';
import media from 'styled-media-query';
import * as analyticsHelper from './common/analyticsHelper';
import * as keys from './common/keys';
import LoadingBars from "./components/LoadingBars";

const StyledForm = styled.form``;

const StyledText = styled.p`
  margin: 0 0 18px 0;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  outline: none;
  display: block;
  margin: 0 0 0.8125em 0;
  padding: 1em;
  width: 100%;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ddd;
  font-family: Gibson;
  font-size: 18px;
`;

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  max-width: 1020px;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.label`
  white-space: nowrap;
`;

const StyledInputValidationText = styled.div`
  font-size: 16px;
  font-family: Gibson;
  color: ${props => props.theme.colors.orange};
  line-height: 0.75em;
  margin: 0 0 0.8125em 0;
`;

const StyledRow = styled.div<{ centered?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.centered ? "center" : "initial")};
  flex-wrap: wrap;

  > * {
    :first-child {
      flex: 1;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  color: ${props => props.theme.colors.orange};
  text-decoration: none;
`;

const SignupLink = styled(StyledLink)`
  text-align: center;
  margin: 18px 0 0 0;
`;

const StyledCheckbox = styled.input`
  ${media.greaterThan("medium")`
     top: -2px;
  `}
  margin: 0 5px 0 0;
  padding: 0;
  width: 14px;
  height: 14px;
  position: relative;
  vertical-align: center;
`;

type SigninProps = {
  currentUser: AuthState['currentUser']
  isLoading: LoadingState['isLoading']
  signin: typeof signin

}

const SigninForm: FC<SigninProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageSignin);
    window.scrollTo(0, 0);
  }, []);

  const [shouldRemember, setShouldRemember] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailValidationMessage, setEmailValidationMessage] = useState('* Email cannot be blank.');
  const [shouldShowEmailValidationMessage, setShouldShowEmailValidationMessage] = useState(false);

  const [passwordValidationMessage, setPasswordValidationMessage] = useState('* Password cannot be blank.');
  const [shouldShowPasswordValidationMessage, setShouldShowPasswordValidationMessage] = useState(false);

  const handleEmailChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
    if (!evt.target.value) {
      setEmailValidationMessage('* Email cannot be blank.');
    } else {
      setEmailValidationMessage('');
      setShouldShowEmailValidationMessage(false);
    }
  };

  const handlePasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setPassword(evt.target.value);
    if (!evt.target.value) {
      setPasswordValidationMessage('* Password cannot be blank.');
    } else {
      setPasswordValidationMessage('');
      setShouldShowPasswordValidationMessage(false);
    }
  };

  const handleShouldRememberChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setShouldRemember(evt.target.checked);
  };

  const inputsAreValid = () => {
    const isEmailValid = email.length > 0;
    const isPasswordValid = password.length > 0;

    if (!isEmailValid) {
      setShouldShowEmailValidationMessage(true);
    }
    if (!isPasswordValid) {
      setShouldShowPasswordValidationMessage(true);
    }

    return isEmailValid && isPasswordValid;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    analyticsHelper.recordClick(keys.AmpElementSigninButton);
    if (inputsAreValid()) {
      props.signin({
        email,
        password,
        shouldRemember,
      }, () => {
        history.push('/dashboard');
      });
    }
  };

  const renderRedirect = () => {
    if (props.currentUser) {
      return <Redirect to='/dashboard' />
    }
  };

  const renderMainContent = () => {
    if (props.isLoading) {
      return (
        <LoadingBars caption='Verifying your credentials...'/>
      )
    } else {
      return (
        <StyledContent>
          <StyledForm onSubmit={handleSubmit}>
            <StyledHeading>Welcome!</StyledHeading>

            <StyledText>
              It's good to have you back. Sign in here and let's get your music heard.
            </StyledText>

            <StyledInput
              type="text"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email address"
            />
            { shouldShowEmailValidationMessage &&
                <StyledInputValidationText>
                  {emailValidationMessage}
                </StyledInputValidationText>
            }
            <StyledInput
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
            />
            { shouldShowPasswordValidationMessage &&
                <StyledInputValidationText>
                  {passwordValidationMessage}
                </StyledInputValidationText>
            }
            <StyledButton
              disabled={props.isLoading}
            >
              Sign in
            </StyledButton>

            <StyledRow>
              <StyledLabel>
                <StyledCheckbox
                  type="checkbox"
                  onChange={handleShouldRememberChange}
                />
                Remember me
              </StyledLabel>

              <StyledLink to="/users/password/new">
                Forgot your password?
              </StyledLink>
            </StyledRow>

            <SignupLink to="/signup">
              If you've never signed up before, click here and do that
            </SignupLink>
          </StyledForm>
        </StyledContent>
      )
    }
  }

  return (
    <StyledContainer>
      {renderRedirect()}
      {renderMainContent()}
    </StyledContainer>
  );
};

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.currentUser,
    isLoading: state.loading.isLoading,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ signin }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(SigninForm)
