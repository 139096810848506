import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { defaultBreakpoints } from "styled-media-query";
import media from "styled-media-query";


const StyledText = styled.p`
  margin: 0 0 18px 0;
`;

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
`;

const StyledDiv = styled.div`
   max-width: 300px;
   ${media.greaterThan("medium")`
    max-width: 600px;
  `}
`;


const StyledInlineLink = styled(Link)`
    display: inline;
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    margin: 0 8px;
`;

const StyledInlineMailLink = styled.a`
    display: inline;
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    margin: 0 8px;
`;

const NotFound: React.FC = props => {
    const isDesktop = useMediaQuery({
        minWidth: defaultBreakpoints.medium
    });

    return (
        <StyledContainer>
            <StyledContent>
                <StyledDiv>
                    <StyledHeading>This page doesn't exist!</StyledHeading>

                    <StyledText>
                        There's a good chance you're looking for your dashboard, but since we built a
                        new site, some of our URLs aren't in the right place.
                    </StyledText>
                    <StyledText>
                        You'll almost definitely find what you're looking for if you head over to the
                        <StyledInlineLink to="/">
                            home page.
                        </StyledInlineLink>
                    </StyledText>
                    <StyledText>
                        If you're still running into issues, you can
                        <StyledInlineMailLink href="mailto:support@artistmgmt.org?Subject=The%20site%20is%20broken!">
                            email us
                        </StyledInlineMailLink>
                        and we'll help you out!
                    </StyledText>
                    <StyledText>
                        While you're here though, might as well watch a frog making the most amazing sounds of all time:
                    </StyledText>
                    <iframe
                        width={isDesktop ? 600 : 300}
                        height={ isDesktop ? 300 : 180}
                        title="frog sounds"
                        src="https://www.youtube.com/embed/cBkWhkAZ9ds"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; fullscreen"
                    >
                    </iframe>
                </StyledDiv>
            </StyledContent>
        </StyledContainer>
    );
};

export default NotFound;
