import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import {DiscountCodeValidationResult} from "../types/Purchasing";
import {SubscriptionType} from "../types/Users";
import * as keys from "../common/keys";
import {getFormattedCurrency} from "../helpers/Utils";

const StyledPlanTitle = styled.div`
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
`;
const StyledPlanPrice = styled(StyledPlanTitle)`
    font-size: 1.2em;  
    color: ${props => props.theme.colors.black};
`;

const StrikeThroughPlanPrice = styled(StyledPlanPrice)`
   text-decoration: line-through;
`;

const StyledTrustMeText = styled(StyledPlanTitle)`
    font-size: 1.0em;  
    font-weight: 300;
    color: #989898;
`;

const StyledDiscountText = styled.div`
  text-align: center;
  line-height: 1.25em;
  font-weight: 400;
  text-transform: uppercase;
  color: ${props => props.theme.colors.orange};
`;

interface DiscountablePlanCardProps {
  className?: string,
  selectedPlan: Exclude<SubscriptionType, null>,
  discountCodeValidationResult: DiscountCodeValidationResult,
}

const StyledDiscountablePlanCard = styled.div`
    border: 2px solid gray;
    padding: 20px 0;
    background-color: #FFFCF3;
    border-radius: 25px;
    box-sizing: border-box;
    transition: 0.3s;
    margin-bottom: 20px;
    width: 100%;
    ${media.greaterThan("medium")`
        width: 300px;
    `}
`;

const StyledBillingFrequencyText = styled(StyledPlanTitle)`
    font-size: 1.0em;
    font-weight: 300;
    color: rgba(52, 51, 51, 0.8);
`;

const DiscountablePlanCard: React.FC<DiscountablePlanCardProps> = ({ className, selectedPlan, discountCodeValidationResult }) => {
  const isDiscounted = !discountCodeValidationResult.includes('NO_OFFER')

  const isMonthly = () => {
    return ['BASIC', 'GOLD', 'PLATINUM'].includes(selectedPlan)
  }

  const regularPriceFormatted = `$${isMonthly() ? keys.plans[selectedPlan].unitPriceUSD : keys.plans[selectedPlan].unitPriceUSD / 12}`


  const getTitleText = () => {
    switch (selectedPlan) {
      case 'BASIC':
      case 'GOLD':
      case 'PLATINUM': {
        return selectedPlan
      }
      case 'PLATINUM_YEARLY': {
        return 'PLATINUM'
      }
      case "GOLD_YEARLY": {
        return 'GOLD'
      }
    }
  }

  const discountSummary = () => {
    switch(discountCodeValidationResult) {
      case DiscountCodeValidationResult.SEVEN_DAY_FREE_TRIAL: {
        return (
          <>
            <StyledDiscountText>{getFormattedCurrency('en', 'USD', 0)} for the first 7 days</StyledDiscountText>
            {!isMonthly() && (
              <>
                <StyledBillingFrequencyText>
                  Then {getFormattedCurrency(
                  'en',
                  'USD',
                  keys.plans[selectedPlan].unitPriceUSD,
                )}/year
                </StyledBillingFrequencyText>
              </>
            )}
          </>
        )
      }
      case DiscountCodeValidationResult.HUNDRED_PERCENT_OFF_1_MONTH: {
        return (
          <>
            <StyledDiscountText>Free for the first {isMonthly() ? 'month' : '12 months'}</StyledDiscountText>
            {!isMonthly() && (
              <StyledBillingFrequencyText>
                Total: {getFormattedCurrency(
                'en',
                'USD',
                0,
              )}
              </StyledBillingFrequencyText>
            )}
          </>
        )
      }
      case DiscountCodeValidationResult.TEN_PERCENT_OFF_1_MONTH: {
        const discountedPriceFormatted = getFormattedCurrency(
          'en',
          'USD',
          isMonthly() ? keys.plans[selectedPlan].tenOffUnitPriceUSD : keys.plans[selectedPlan].tenOffUnitPriceUSD / 12,
        )
        return (
          <>
            <StyledDiscountText>{discountedPriceFormatted} for the first {isMonthly() ? 'month' : '12 months'}</StyledDiscountText>
            {!isMonthly() && (
              <StyledBillingFrequencyText>
                Total: {getFormattedCurrency(
                'en',
                'USD',
                keys.plans[selectedPlan].tenOffUnitPriceUSD,
              )}
              </StyledBillingFrequencyText>
            )}
          </>
        )
      }
      case DiscountCodeValidationResult.THIRTY_PERCENT_OFF_1_MONTH: {
        const discountedPriceFormatted = getFormattedCurrency(
          'en',
          'USD',
          isMonthly() ? keys.plans[selectedPlan].thirtyOffUnitPriceUSD : keys.plans[selectedPlan].thirtyOffUnitPriceUSD / 12,
        )
        return (
          <>
            <StyledDiscountText>{discountedPriceFormatted} for the first {isMonthly() ? 'month' : '12 months'}</StyledDiscountText>
            {!isMonthly() && (
              <StyledBillingFrequencyText>
                Total: {getFormattedCurrency(
                'en',
                'USD',
                keys.plans[selectedPlan].thirtyOffUnitPriceUSD,
              )}
              </StyledBillingFrequencyText>
            )}
          </>
        )
      }
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_1_MONTH: {
        const discountedPriceFormatted = getFormattedCurrency(
          'en',
          'USD',
          isMonthly() ? keys.plans[selectedPlan].fiftyOffUnitPriceUSD : keys.plans[selectedPlan].fiftyOffUnitPriceUSD / 12,
        )
        return (
          <>
            <StyledDiscountText>{discountedPriceFormatted} for the first {isMonthly() ? 'month' : '12 months'} </StyledDiscountText>
            {!isMonthly() && (
              <StyledBillingFrequencyText>
                Total: {getFormattedCurrency(
                'en',
                'USD',
                keys.plans[selectedPlan].fiftyOffUnitPriceUSD,
              )}
              </StyledBillingFrequencyText>
            )}
          </>
        )
      }
      case DiscountCodeValidationResult.FIFTY_PERCENT_OFF_6_MONTHS: {
        const discountedPriceFormatted = getFormattedCurrency(
          'en',
          'USD',
          isMonthly() ? keys.plans[selectedPlan].fiftyOffUnitPriceUSD : keys.plans[selectedPlan].fiftyOffUnitPriceUSD / 12,
        )
        return (
          <>
            <StyledDiscountText>{discountedPriceFormatted} for the first {isMonthly() ? 6 : 12} months</StyledDiscountText>
            {!isMonthly() && (
              <StyledBillingFrequencyText>
                Total: {getFormattedCurrency(
                'en',
                'USD',
                keys.plans[selectedPlan].fiftyOffUnitPriceUSD,
              )}
              </StyledBillingFrequencyText>
            )}
          </>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <StyledDiscountablePlanCard className={className}>
      <StyledPlanTitle>
        {getTitleText()}
      </StyledPlanTitle>
      {isDiscounted ?
        <>
          <StrikeThroughPlanPrice>
            {regularPriceFormatted}/month
          </StrikeThroughPlanPrice>
          {discountSummary()}
        </>
        :
        <>
          <StyledPlanPrice>
            {regularPriceFormatted}/month
          </StyledPlanPrice>
          {!isMonthly() && (
            <StyledBillingFrequencyText>
              Total: {getFormattedCurrency(
              'en',
              'USD',
              keys.plans[selectedPlan].unitPriceUSD,
            )}
            </StyledBillingFrequencyText>
          )}
        </>
      }
      <StyledTrustMeText>
        Change or cancel anytime
      </StyledTrustMeText>
    </StyledDiscountablePlanCard>
  );
};

export default DiscountablePlanCard;