import React, { useEffect } from "react";
import Hero from "./Hero";
import festivalImg from "./images/festival.jpg";
import styled from "styled-components";
import media from "styled-media-query";
import * as analyticsHelper from './common/analyticsHelper';
import * as keys from './common/keys';
import ReactPlayer from "react-player/vimeo";
import {AmplitudeEventProps} from "./types/AmplitudeEventProps";
import {useHistory} from "react-router-dom";
import Button from "./Button";

const StyledText = styled.div`
  margin: 0 0 18px 0;
  h3 {
    font-weight: 600;
    font-size: 1em; 
  }
`;

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
`;

const StyledDiv = styled.div`
   max-width: 300px;
   ${media.greaterThan("medium")`
    max-width: 650px;
  `}
`;

const StyledTitle = styled.h1`
    text-transform: uppercase;
    font-size: 30px !important;
    ${media.greaterThan("medium")`
      font-size: 2.5em !important;
    `}
`;

const VideoContainer = styled.div`
  position: relative;
  height: 400px;
  flex-basis: 50%;
  ${media.lessThan("large")`
    margin-top: 64px;
  `}
`

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 20px;
  ${media.lessThan("large")`
    flex-direction: column;
    height: 600px;
  `}
  ${media.lessThan("medium")`
    height: unset;
  `}
`

const VideoCaption = styled.div`
  color: ${props => props.theme.colors.black};
  margin: 0 24px;
`

const HeroText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const HowItWorks: React.FC = () => {
  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageHowItWorks);
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  const handleSignUpButtonClick = (ctaText: string) => {
    const ampEventProps: AmplitudeEventProps = {
      [keys.AmpPropButtonCTA]: ctaText,
    };
    analyticsHelper.recordClick(keys.AmpElementSignupCTA, ampEventProps);
    history.push('/signup')
  };

  return (
    <>
      <Hero backgroundUrl={festivalImg}>
        <HorizontalContainer>
          <HeroText>
            <StyledTitle>How it works</StyledTitle>
            <p>A brief overview of Artist Management</p>
            <Button onClick={() => handleSignUpButtonClick( 'Get started' )}>Get started</Button>
          </HeroText>
          <VideoContainer>
            <ReactPlayer
                url='https://player.vimeo.com/video/835724217'
                controls={true}
                height={'100%'}
                width={'100%'}
                config={{
                  playerOptions: {
                    autopause: 'true',
                    background: 'false',
                  },
                }}
            />
            <VideoCaption>One of our users walks you through how he uses Artist Management</VideoCaption>
          </VideoContainer>
        </HorizontalContainer>
      </Hero>

      <StyledContainer>
        <StyledContent>
          <StyledDiv>
            <StyledHeading>Strategic Following</StyledHeading>
            <StyledText>
              On SoundCloud, each time you follow another account, a notification is triggered on that account, prompting that user to check out your music and follow you back.
            </StyledText>
            <StyledText>
              In growing your fanbase, the goal is to acquire
              targeted followers; these are people who are both active on SoundCloud and also interested in the type of music you are producing.
              Instead of empty accounts, you want people who would naturally like, comment and repost your material.
            </StyledText>
            <StyledText>
              The success of Strategic Following is based on how accurately you choose whom to follow, and how consistently you repeat the process each day.
            </StyledText>
            <StyledHeading>How Artist Management finds you targeted followers</StyledHeading>
            <StyledText>
              Artist Management finds you active SoundCloud listeners who have either liked
              or reposted music similar to yours or have followed an artist who makes music similar to yours.
            </StyledText>
            <StyledText>
              You can feed the algorithm by providing a SoundCloud URL of a track that you think is similar in vibe to your music. This way, the algorithm
              uses the track to extract a list of active SoundCloud listeners that it will automatically follow on your behalf over time.
            </StyledText>
            <StyledText>
              Alternatively, it is also possible to feed the algorithm by providing a SoundCloud URL of an artist similar to you.
              The algorithm identifies and extracts only the active SoundCloud listeners who follow that artist. Again, these active SoundCloud listeners are added to a list and automatically followed by your account over time.
            </StyledText>
            <StyledText>
              We have found that by consistently repeating the above, you end up with targeted followers who naturally want to engage with you and your music. This is why we are proud to be able to provide so many artists with our service.
            </StyledText>
            <StyledHeading>How Artist Management maintains a healthy follower-to-following ratio for you</StyledHeading>
            <StyledText>
              Artist Management stores the list of accounts that you follow in a database and automatically unfollows
              accounts that it followed after a certain length of time. You may choose between letting it only
              unfollow accounts that don't follow back or letting it unfollow all accounts that it followed.
            </StyledText>
            <StyledHeading>
              Staying within limits set by SoundCloud
            </StyledHeading>
            <StyledText>
              To prevent people from spamming other accounts with notifications and from using mass-follow
              bots, SoundCloud imposes certain limits on actions a user can perform per day. Artist Management
              takes these limits very seriously and makes sure to stay within the limits so that you
              never risk being banned by SoundCloud.
              {/*As a result, Artist Management performs 40 follows and 40 unfollows spaced evenly throughout the day.*/}
              {/*This results in about 1200 follows and unfollows per month.*/}
            </StyledText>
            <StyledHeading>
              Never follow the same account twice
            </StyledHeading>
            <StyledText>
              Artist Management holds the reputation of our customers in highest regard. This
              is why our algorithm will never annoy other accounts by following them twice.
              If an account is unfollowed by Artist Management on your behalf, then it is stored in a database so
              that it does not follow this account again. This also ensures that when you feed the algorithm
              with a new track or artist, the accounts that it finds to follow are new accounts that you
              don't currently follow and haven't followed in the past.
            </StyledText>
            <StyledHeading>
              Pause following activity at any time
            </StyledHeading>
            <StyledText>
              If at any point you feel that you want to pause your following activity, Artist Management offers
              you the option to do this. Pausing your following activity does not halt your unfollowing activity.
              This means that if accounts are eligible to be unfollowed, they will continue to be unfollowed.
              This allows you to be in control in case you need to bring your following count down faster
              before submitting a track to a blog or label.
            </StyledText>
            <StyledHeading>
              Pleasant side-effects on your SoundCloud stats
            </StyledHeading>
            <StyledText>
              In addition to the exposure that your account gets from Strategic Following, we have
              observed that just by letting Artist Management keep your account active by following and/or unfollowing accounts
              each day, SoundCloud gives your account additional exposure in the streams and searches of other users. As a result
              don't be surprised if you get more follows, likes, and reposts even from accounts outside of Artist Management.
            </StyledText>
          </StyledDiv>
        </StyledContent>
      </StyledContainer>
    </>
  );
};

export default HowItWorks;
