import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-family: Gibson;
  font-weight: 600;
`;

export default StyledLink;
