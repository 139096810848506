import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyBqNMJwC4BUdjLaq23qZoDzf4Vu9WMfCzA",
    authDomain: "followfreak-2020.firebaseapp.com",
    databaseURL: "https://followfreak-2020.firebaseio.com",
    projectId: "followfreak-2020",
    storageBucket: "followfreak-2020.appspot.com",
    messagingSenderId: "41790624565",
    appId: "1:41790624565:web:a732d2cb1e79f39a2db27c",
    measurementId: "G-RGCY3CWMV6"
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
