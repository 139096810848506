import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PlanSelector from './components/PlanSelector';
import { AuthState, RootState } from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SelectedPlanType, SubscriptionType } from './types/Users';
import SoundCloudUsersModal from './SoundCloudUsersModal';
import Button from './Button';
import { logEvent } from 'firebase/analytics';
import { analytics } from './common/firebase';
import { changeSubscription, resumeSubscription } from './actions'

const StyledButton = styled(Button)`
  display: block;
  box-sizing: border-box;
  margin: 0 0 0.8125em 0;
  white-space: nowrap;
  width: 300px;
  align-self: center;
`;

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
`;

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  margin: auto;
`;

const StyledDiv = styled.div`

`;

const StyledConfirmationText = styled.div`
    margin: 1.5em;
    text-align: center;
`;

const StyledCancelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3em;
`;
const StyledDiscouragedLink = styled.a`
    color: #646464;
    text-decoration: none;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
`;

const StyledEncouragedLink = styled.a`
    color: ${props => props.theme.colors.orange};
    text-decoration: none;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
`;

const StyledConfirmationModalContainer = styled.div`
    height: calc(100% - 0.5em);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

type EditSubscriptionPageProps = {
    currentUser: AuthState['currentUser'],
    changeSubscription: typeof changeSubscription,
    resumeSubscription: typeof resumeSubscription,
}

const EditSubscriptionPage: React.FC<EditSubscriptionPageProps> = (props) => {
    const history = useHistory();

    useEffect(() => {
        logEvent(analytics, 'ff_page_view', {
            page_title: 'edit_subscription'
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [selectedPlan, setSelectedPlan] = useState<SubscriptionType>(props.currentUser?.subscription ?? 'GOLD');
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [showYearlyPlans, setShowYearlyPlans] = useState<boolean>(['GOLD_YEARLY', 'PLATINUM_YEARLY'].includes(props.currentUser?.subscription as string))

    const { currentUser } = props

    if (!currentUser) {
        return null
    }

    const handleCardClick = (plan: SelectedPlanType) => {
        if (!currentUser.selectedPlan) {
            return;
        }
        if (plan !== currentUser.subscription) {
            const selection = plan === 'INTRO' ? 'BASIC' : plan
            setSelectedPlan(selection);
            setIsConfirmationModalOpen(true);
            logEvent(analytics, 'ff_button_click', {
                name: `card_change_plan_to_${plan}`
            });
        }
    };

    const handleToggleShowYearlyPlans = () => {
        setShowYearlyPlans(!showYearlyPlans)
    }

    const handleModalClose = () => {
        setIsConfirmationModalOpen(false);
        setSelectedPlan(currentUser.subscription)
    };

    const handleConfirmClick = (evt: MouseEvent) => {
        evt.preventDefault();
        props.changeSubscription(currentUser, selectedPlan, () => {
            history.push('/dashboard');
        });
        logEvent(analytics, 'ff_button_click', {
            name: `confirm_change_plan_to_${selectedPlan}`
        });
    };

    const handleCancelSubscriptionClick = (evt: MouseEvent) => {
        evt.preventDefault();
        logEvent(analytics, 'ff_button_click', {
            name: `cancel_subscription_link`
        });
        history.push('/subscription/cancel');
    };

    const handleResumeSubscriptionClick = (evt: MouseEvent) => {
        evt.preventDefault();
        logEvent(analytics, 'ff_button_click', {
            name: `resume_subscription_link`
        });
        props.resumeSubscription(currentUser, () => {
            history.push('/dashboard');
        });
    };

    const isYearly = (): boolean => {
        return ['GOLD_YEARLY', 'PLATINUM_YEARLY'].includes(currentUser.subscription as string);
    }

    const renderSubscriptionLink = () => {
        if (currentUser.selectedPlan === null) {
            return (
                <StyledDiscouragedLink onClick={handleResumeSubscriptionClick}>Resume Subscription</StyledDiscouragedLink>
            );
        } else {
            return (
                <StyledDiscouragedLink onClick={handleCancelSubscriptionClick}>Cancel Subscription</StyledDiscouragedLink>
            );
        }
    };

    return (
        <StyledContainer>
            <StyledContent>
                <StyledDiv>
                    <SoundCloudUsersModal isModalOpen={isConfirmationModalOpen} closeModal={handleModalClose} title='Confirm Plan Change'>
                        <StyledConfirmationModalContainer>
                            <StyledConfirmationText>
                                Please confirm that you would like to change your plan from <br/>{currentUser.subscription} to {selectedPlan}
                            </StyledConfirmationText>
                            <StyledButton onClick={handleConfirmClick}>Confirm</StyledButton>
                        </StyledConfirmationModalContainer>
                    </SoundCloudUsersModal>
                    <StyledHeading>Change Plans</StyledHeading>
                    <PlanSelector planTypes={showYearlyPlans ? 'yearly' : 'monthly'} includeBasicPlan={process.env.REACT_APP_INCLUDE_BASIC_PLAN === 'true'  || currentUser.subscription === 'BASIC'} onCardClick={handleCardClick} isDisabled={currentUser.selectedPlan === null} selectedPlanName={selectedPlan} />
                </StyledDiv>
                {!isYearly() &&
                    <StyledCancelContainer>
                        <StyledEncouragedLink onClick={handleToggleShowYearlyPlans}>Show {showYearlyPlans ? 'Monthly' : 'Yearly'} Plans</StyledEncouragedLink>
                    </StyledCancelContainer>
                }
                <StyledCancelContainer>
                    {renderSubscriptionLink()}
                </StyledCancelContainer>
            </StyledContent>
        </StyledContainer>
    );
};
function mapStateToProps(state: RootState) {
    return {
        currentUser: state.auth.currentUser,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        resumeSubscription,
        changeSubscription,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(EditSubscriptionPage);
