import React, { useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";

const StyledContainer = styled.div`
  background-color: #faf6e8;
  padding: 3em 1em;
`;

const StyledContent = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
`;

const StyledDiv = styled.div`
   max-width: 340px;
   ${media.greaterThan("medium")`
    max-width: 600px;
  `}
`;

const Privacy: React.FC = props => {

    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);

    return (
        <StyledContainer>
            <StyledContent>
                <StyledDiv>
                    <p>
                        PRIVACY POLICY
                    </p>
                    <p>
                        Last updated May 25, 2024
                    </p>
                    <p>
                        Thank you for choosing to be part of our community at Artist Management (“company”, “we”, “us”,
                        or “our”). We are committed to protecting your personal information and your right to privacy.
                        If you have any questions or concerns about our policy, or our practices with regards to your
                        personal information, please contact us at support@artistmgmt.org.
                    </p>
                    <p>
                        When you visit our website https://www.artistmgmt.org/, and use our services, you trust us
                        with your personal information. We take your privacy very seriously. In this privacy notice, we
                        describe our privacy policy. We seek to explain to you in the clearest way possible what
                        information we collect, how we use it and what rights you have in relation to it. We hope you
                        take some time to read through it carefully, as it is important. If there are any terms in this
                        privacy policy that you do not agree with, please discontinue use of our Sites and our services.
                    </p>
                    <p>
                        This privacy policy applies to all information collected through our website (such as
                        https://www.artistmgmt.org/), and/or any related services, sales, marketing or events (we
                        refer to them collectively in this privacy policy as the "Sites").
                    </p>
                    <p>
                        Please read this privacy policy carefully as it will help you make informed decisions about
                        sharing your personal information with us.
                    </p>
                    <p>
                        TABLE OF CONTENTS
                    </p>
                    <p>
                        1. WHAT INFORMATION DO WE COLLECT?
                    </p>
                    <p>
                        2. HOW DO WE USE YOUR INFORMATION?
                    </p>
                    <p>
                        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </p>
                    <p>
                        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </p>
                    <p>
                        5. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </p>
                    <p>
                        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </p>
                    <p>
                        7. DO WE COLLECT INFORMATION FROM MINORS?
                    </p>
                    <p>
                        8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </p>
                    <p>
                        9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </p>
                    <p>
                        10. DO WE MAKE UPDATES TO THIS POLICY?
                    </p>
                    <p>
                        11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </p>
                    <br/>
                    <p>
                        1. WHAT INFORMATION DO WE COLLECT?
                    </p>
                    <p>
                        Personal information you disclose to us
                    </p>
                    <p>
                        In Short: We collect personal information that you provide to us such as name, address, contact
                        information, passwords and security data, and payment information.
                    </p>
                    <p>
                        We collect personal information that you voluntarily provide to us when registering at the Sites
                        expressing an interest in obtaining information about us or our products and services, when
                        participating in activities on the Sites or otherwise contacting us.
                    </p>
                    <p>
                        The personal information that we collect depends on the context of your interactions with us and
                        the Sites, the choices you make and the products and features you use. The personal information
                        we collect can include the following:
                    </p>
                    <p>
                        Name and Contact Data. We collect your first and last name, email address, postal address, phone
                        number, and other similar contact data.
                    </p>
                    <p>
                        Credentials. We collect passwords, password hints, and similar security information used for
                        authentication and account access.
                    </p>
                    <p>
                        Payment Data. We collect data necessary to process your payment if you make purchases, such as
                        your payment instrument number (such as a credit card number), and the security code associated
                        with your payment instrument. All payment data is stored by our payment processor and you should
                        review its privacy policies and contact the payment processor directly to respond to your questions.
                    </p>
                    <p>
                        All personal information that you provide to us must be true, complete and accurate, and you must
                        notify us of any changes to such personal information.
                    </p>
                    <p>
                        Information automatically collected
                    </p>
                    <p>
                        In Short: Some information – such as IP address and/or browser and device characteristics – is
                        collected automatically when you visit our Sites.
                    </p>
                    <p>
                        We automatically collect certain information when you visit, use or navigate the Sites. This
                        information does not reveal your specific identity (like your name or contact information)
                        but may include device and usage information, such as your IP address, browser and device
                        characteristics, operating system, language preferences, referring URLs, device name, country,
                        location, information about how and when you use our Sites and other technical information. This
                        information is primarily needed to maintain the security and operation of our Sites, and for our
                        internal analytics and reporting purposes.
                    </p>
                    <p>
                        Like many businesses, we also collect information through cookies and similar technologies.
                    </p>
                    <p>
                        Information collected from other sources
                    </p>
                    <p>
                        In Short: We may collect limited data from public databases, marketing partners, and other
                        outside sources.
                    </p>
                    <p>
                        We may obtain information about you from other sources, such as public databases, joint
                        marketing partners, as well as from other third parties. Examples of the information we
                        receive from other sources include: social media profile information; marketing leads and
                        search results and links, including paid listings (such as sponsored links).
                    </p>
                    <p>
                        2. HOW DO WE USE YOUR INFORMATION?
                    </p>
                    <p>
                        In Short: We process your information for purposes based on legitimate business interests,
                        the fulfillment of our contract with you, compliance with our legal obligations, and/or your
                        consent.
                    </p>
                    <p>
                        We use personal information collected via our Sites for a variety of business purposes described
                        below. We process your personal information for these purposes in reliance on our legitimate
                        business interests ("Business Purposes"), in order to enter into or perform a contract with you
                        ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations
                        ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each
                        purpose listed below.
                    </p>
                    <p>
                        We use the information we collect or receive:
                    </p>
                    <p>
                        To facilitate account creation and logon process with your Consent. If you choose to link your
                        account with us to a third party account *(such as your Google or Facebook account), we use the
                        information you allowed us to collect from those third parties to facilitate account creation and
                        logon process. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further
                        information.
                    </p>
                    <p>
                        To send you marketing and promotional communications for Business Purposes and/or with your
                        Consent. We and/or our third party marketing partners may use the personal information you send
                        to us for our marketing purposes, if this is in accordance with your marketing preferences. You
                        can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
                    </p>
                    <p>
                        To send administrative information to you possibly for Contractual. We may use your personal
                        information to send you product, service and new feature information and/or information about
                        changes to our terms, conditions, and policies.
                    </p>
                    <p>
                        Request Feedback for our Business Purposes. We may use your information to request feedback and
                        to contact you about your use of our Sites.
                    </p>
                    <p>
                        To enforce our terms, conditions and policies for Legal Reasons.
                    </p>
                    <p>
                        To respond to legal requests and prevent harm for Legal Reasons. If we receive a subpoena or
                        other legal request, we may need to inspect the data we hold to determine how to respond.
                    </p>
                    <p>
                        WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </p>
                    <p>
                        In Short: We only share information with your consent, to comply with laws, to protect your
                        rights, or to fulfill business obligations.
                    </p>
                    <p>
                        We only share and disclose your information in the following situations:
                    </p>
                    <p>
                        Compliance with Laws. We may disclose your information where we are legally required to do so
                        in order to comply with applicable law, governmental requests, a judicial proceeding, court
                        order, or legal process, such as in response to a court order or a subpoena (including in
                        response to public authorities to meet national security or law enforcement requirements).
                    </p>
                    <p>
                        Vital Interests and Legal Rights. We may disclose your information where we believe it
                        is necessary to investigate, prevent, or take action regarding potential violations of our
                        policies, suspected fraud, situations involving potential threats to the safety of any person
                        and illegal activities, or as evidence in litigation in which we are involved.
                    </p>
                    <p>
                        Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third
                        party vendors, service providers, contractors or agents who perform services for us or on our
                        behalf and require access to such information to do that work. Examples include: payment
                        processing, data analysis, email delivery, hosting services, customer service and marketing
                        efforts. We may allow selected third parties to use tracking technology on the Sites, which
                        will enable them to collect data about how you interact with the Sites over time. This
                        information may be used to, among other things, analyze and track data, determine the
                        popularity of certain content and better understand online activity. Unless described in this
                        Policy, we do not share, sell, rent or trade any of your information with third parties for
                        their promotional purposes.
                    </p>
                    <p>
                        Business Transfers. We may share or transfer your information in connection with, or during
                        negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                        portion of our business to another company.
                    </p>
                    <p>
                        With your Consent. We may disclose your personal information for any other purpose with your consent.
                    </p>
                    <p>
                        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </p>
                    <p>
                        In Short: We may use cookies and other tracking technologies to collect and store your information.
                    </p>
                    <p>
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                        store information. Specific information about how we use such technologies and how you can
                        refuse certain cookies is set out in our Cookie Policy.
                    </p>
                    <p>
                        5. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </p>
                    <p>
                        In Short: We keep your information for as long as necessary to fulfill the purposes outlined in
                        this privacy policy unless otherwise required by law.
                    </p>
                    <p>
                        We will only keep your personal information for as long as it is necessary for the purposes set
                        out in this privacy policy, unless a longer retention period is required or permitted by law
                        (such as tax, accounting or other legal requirements). No purpose in this policy will require
                        us keeping your personal information for longer than the period of time in which users have an
                        account with us.
                    </p>
                    <p>
                        When we have no ongoing legitimate business need to process your personal information, we will
                        either delete or anonymize it, or, if this is not possible (for example, because your personal
                        information has been stored in backup archives), then we will securely store your personal
                        information and isolate it from any further processing until deletion is possible.
                    </p>
                    <p>
                        6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </p>
                    <p>
                        In Short: We aim to protect your personal information through a system of organizational and
                        technical security measures.
                    </p>
                    <p>
                        We have implemented appropriate technical and organizational security measures designed to
                        protect the security of any personal information we process. However, please also remember
                        that we cannot guarantee that the internet itself is 100% secure. Although we will do our best
                        to protect your personal information, transmission of personal information to and from our Sites
                        is at your own risk. You should only access the services within a secure environment.
                    </p>
                    <p>
                        7. DO WE COLLECT INFORMATION FROM MINORS?
                    </p>
                    <p>
                        In Short: We do not knowingly collect data from or market to children under 18 years of age.
                    </p>
                    <p>
                        We do not knowingly solicit data from or market to children under 18 years of age. By using the
                        Sites, you represent that you are at least 18 or that you are the parent or guardian of such a
                        minor and consent to such minor dependent’s use of the Sites. If we learn that personal
                        information from users less than 18 years of age has been collected, we will deactivate the
                        account and take reasonable measures to promptly delete such data from our records. If you
                        become aware of any data we have collected from children under age 18, please contact us at
                        support@artistmgmt.org.
                    </p>
                    <p>
                        8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </p>
                    <p>
                        In Short: You may review, change, or terminate your account at any time.
                    </p>
                    <p>
                        If you are resident in the European Economic Area and you believe we are unlawfully processing
                        your personal information, you also have the right to complain to your local data protection
                        supervisory authority. You can find their contact details here:
                    </p>
                    <p>
                        https://ec.europa.eu/justice/data-protection
                    </p>
                    <p>
                        Account Information
                    </p>
                    <p>
                        If you would at any time like to review or change the information in your account or terminate
                        your account, you can:
                    </p>
                    <p>
                        ■ Log into your account settings and update your user account.
                    </p>
                    <p>
                        ■ Contact us using the contact information provided.
                    </p>
                    <p>
                        Upon your request to terminate your account, we will deactivate or delete your account and
                        information from our active databases. However, some information may be retained in our files
                        to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of
                        Use and/or comply with legal requirements.
                    </p>
                    <p>
                        Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If
                        you prefer, you can usually choose to set your browser to remove cookies and to reject cookies.
                        If you choose to remove cookies or reject cookies, this could affect certain features or
                        services of our Sites. To opt-out of interest-based advertising by advertisers on our Sites
                        visit http://www.aboutads.info/choices/ .
                    </p>
                    <p>
                        Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
                        clicking on the unsubscribe link in the emails that we send or by contacting us using the details
                        provided below. You will then be removed from the marketing email list – however, we will still
                        need to send you service-related emails that are necessary for the administration and use of your
                        account. To otherwise opt-out, you may:
                    </p>
                    <p>
                        ■ Access your account settings and update preferences.
                    </p>
                    <p>
                        ■ Contact us using the contact information provided.
                    </p>
                    <p>
                        9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </p>
                    <p>
                        In Short: Yes, if you are a resident of California, you are granted specific rights regarding
                        access to your personal information.
                    </p>
                    <p>
                        California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our
                        users who are California residents to request and obtain from us, once a year and free of
                        charge, information about categories of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and addresses of all third parties with
                        which we shared personal information in the immediately preceding calendar year. If you are a
                        California resident and would like to make such a request, please submit your request in writing
                        to us using the contact information provided below.
                    </p>
                    <p>
                        If you are under 18 years of age, reside in California, and have a registered account with the
                        Sites, you have the right to request removal of unwanted data that you publicly post on the
                        Sites. To request removal of such data, please contact us using the contact information
                        provided below, and include the email address associated with your account and a statement
                        that you reside in California. We will make sure the data is not publicly displayed on the
                        Sites, but please be aware that the data may not be completely or comprehensively removed
                        from our systems.
                    </p>
                    <p>
                        10. DO WE MAKE UPDATES TO THIS POLICY?
                    </p>
                    <p>
                        In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
                    </p>
                    <p>
                        We may update this privacy policy from time to time. The updated version will be indicated by
                        an updated “Revised” date and the updated version will be effective as soon as it is accessible.
                        If we make material changes to this privacy policy, we may notify you either by prominently
                        posting a notice of such changes or by directly sending you a notification. We encourage you
                        to review this privacy policy frequently to be informed of how we are protecting your information.
                    </p>
                    <p>
                        11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </p>
                    <p>
                        If you have questions or comments about this policy, you may contact our Data Protection
                        Officer division by email at support@artistmgmt.org, or by post to:
                    </p>
                    <p>
                        Artist Management
                    </p>
                    <p>
                        140 New Montgomery St
                    </p>
                    <p>
                        San Francisco, CA 94105
                    </p>
                    <p>
                        United States
                    </p>
                    <p>
                        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </p>
                    <p>
                        Based on the laws of some  countries, you may have the right to request access to the personal
                        information we collect from you, change that information, or delete it in some circumstances.
                        To request to review, update, or delete your personal information, please submit a request form
                        by clicking here. We will respond to your request within 30 days.
                    </p>
                </StyledDiv>
            </StyledContent>
        </StyledContainer>
    );
};

export default Privacy;
