import { FlashState } from '../types/States';
import { FlashErrorAction, FlashSuccessAction, ReduxAction } from '../types/ReduxActions'
import { FLASH_SUCCESS, FLASH_ERROR } from '../actions/types';

const INITIAL_STATE: FlashState = {
    successMessage: '',
    errorMessage: '',
};

export default function flashReducer(state = INITIAL_STATE, action: ReduxAction) {
    switch(action.type) {
        case FLASH_SUCCESS:
            const successAction: FlashSuccessAction = action;
            return { ...state, successMessage: successAction.payload };
        case FLASH_ERROR:
            const errorAction: FlashErrorAction = action;
            return { ...state, errorMessage: errorAction.payload};
        default:
            return state;
    }
}
