import React, { useEffect } from 'react';
import styled from 'styled-components';
import {AuthState, RootState} from './types/States';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import media from 'styled-media-query';
import PlanSelector from './components/PlanSelector';
import { SelectedPlanType } from './types/Users';
import { logEvent } from 'firebase/analytics';
import { analytics } from './common/firebase';
import * as analyticsHelper from './common/analyticsHelper';
import * as keys from './common/keys';
import { selectPlan } from './actions'

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.2em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
  ${media.greaterThan("medium")`
    font-size: 1.5em;
  `}
`;

const PlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;
const StyledPlanSelector = styled(PlanSelector)`
    width: 100%;
`;

type PlanSelectionProps = {
  selectPlan: typeof selectPlan
  currentUser: AuthState["currentUser"]
  showYearlyPlans: boolean,
}

const PlanSelection: React.FC<PlanSelectionProps> = (props ) => {

    useEffect(() => {
        logEvent(analytics, 'ff_page_view', {
            page_title: 'plan_selection'
        });
        analyticsHelper.recordPageView(keys.AmpPagePlanSelection)
    }, []);

    const { currentUser, showYearlyPlans } = props
    if (!currentUser) {
      return null
    }

    const handlePlanSelection = (plan: SelectedPlanType) => {
        props.selectPlan(currentUser, plan);
        logEvent(analytics, 'ff_button_click', {
            name: `plan_card_${plan}`
        });
    };

    return (
        <PlansContainer>
            <StyledHeading>Select a plan</StyledHeading>
            {/*<StyledDualTabSelector*/}
            {/*  tab1Label='Yearly Plans'*/}
            {/*  tab2Label='Monthly Plans'*/}
            {/*  selectedTab={selectedTab}*/}
            {/*  onTabClicked={handlePlanFrequencySelection}*/}
            {/*/>*/}
            <StyledPlanSelector
              planTypes={showYearlyPlans ? 'yearly' : 'monthly'}
              onCardClick={handlePlanSelection}
              isDisabled={false}
              selectedPlanName={currentUser.selectedPlan}
              includeBasicPlan={process.env.REACT_APP_INCLUDE_BASIC_PLAN === 'true'}
              includeIntroPlan={process.env.REACT_APP_INCLUDE_INTRO_PLAN === 'true'}
            />
        </PlansContainer>
    );
};

function mapStateToProps(state: RootState) {
    return {
        currentUser: state.auth.currentUser,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    selectPlan
  }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(PlanSelection);
