export const getFormattedCurrency = (locale: string, currency: string, amount: number): string => {
  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    })

    return formatter.format(amount)
  } catch (error) {
    console.log('Failed to format amount with currency')
    return amount.toString()
  }
}