import React from 'react';
import styled from 'styled-components';
import {CardElement} from '@stripe/react-stripe-js';
import './CardSection.css'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};
const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 300px;
`;



function CardSection() {
    return (
        <StyledContainer>
            <div>Credit Card Details:</div>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </StyledContainer>
    );
};

export default CardSection;