import styled from 'styled-components';

const FlashMessage = styled.div`
  text-align: center;
  display: block;
  color: ${props => props.theme.colors.white};
  line-height: 22.5px;
  font-weight: 600;
  font-size: 18px;
  padding: 9px 18px;
  border-top: 2px solid ${props => props.theme.colors.white};
  border-bottom: 2px solid ${props => props.theme.colors.white};
`;

export default FlashMessage;