import { createGlobalStyle } from 'styled-components';

import gibsonBold from './fonts/Gibson-Bold.woff';
import gibsonRegular from './fonts/Gibson-Regular.woff';
import gibsonSemiBold from './fonts/Gibson-SemiBold.woff';
import oswald from './fonts/Oswald.woff2';
import calculator from './fonts/Calculator.woff'

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: "Gibson";
  font-weight: normal;
  src: url(${gibsonRegular}) format("woff");
  }

  @font-face {
    font-family: "Gibson";
    font-weight: 600;
    src: url(${gibsonSemiBold}) format("woff"); 
  }

  @font-face {
    font-family: "Gibson";
    font-weight: bold;
    src: url(${gibsonBold}) format("woff"); 
  }
  
  @font-face {
      font-family: "Oswald";
      font-weight: 400;
      src: url(${oswald}) format("woff2");
  }

  @font-face {
      font-family: "Calculator";
      font-weight: normal;
      src: url(${calculator}) format("woff")
  }

  body {
    font-family: Gibson;
    font-size: 18px;
    color: ${props => props.theme.colors.black};
    line-height: 1.625; 
  }
`;

export default GlobalStyle;
