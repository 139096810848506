import React from "react";
import Switch from "react-switch";
import styled from "styled-components";
import media, {defaultBreakpoints} from "styled-media-query";
import {useMediaQuery} from "react-responsive";
import Tooltip from "@mui/material/Tooltip";

interface AMToggleProps {
  isOn?: boolean,
  label: string,
  tooltipTitle: string | undefined
  onToggle: (isOn: boolean) => void
  disabled?: boolean,
  className?: string
}

const StyledSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 12px;
`;

const StyledSwitchLabel = styled.div`
  margin-right: 5px;
  font-size: 12px;
  ${media.greaterThan("medium")`
    font-size: 16px;
  `}
`;

const AMToggle: React.FC<AMToggleProps> = ({ className, tooltipTitle, label, isOn, onToggle, disabled = false }) => {
  const isDesktop = useMediaQuery({
    minWidth: defaultBreakpoints.medium
  });

  if (isOn === undefined) {
    return null
  }

  return (
    <Tooltip title={tooltipTitle} arrow>
      <StyledSwitchContainer className={className}>
        <StyledSwitchLabel>{label}</StyledSwitchLabel>
        <Switch
          onColor={'#F5BF4F'}
          uncheckedIcon={false}
          checkedIcon={false}
          disabled={disabled}
          handleDiameter={isDesktop ? 24 : 16}
          height={isDesktop ? 28 : 18}
          width={isDesktop ? 50 : 35}
          onChange={onToggle}
          checked={isOn}/>
      </StyledSwitchContainer>
    </Tooltip>
  );
};

export default AMToggle;