import React, { useEffect } from 'react';
import styled from 'styled-components';
import {AuthState, RootState} from './types/States';
import {bindActionCreators, compose, Dispatch} from 'redux';
import { connect } from 'react-redux';
import { fetchAnalyticsData } from "./actions";

import AnalyticsDashboard from './AnalyticsDashboard';
import ReferralCodeBox from './components/ReferralCodeBox';

const StyledContent = styled.div`
    padding: 3em 1em;
`;

interface AnalyticsProps {
    isBetrayersModalOpen?: boolean,
    isToFollowUsersModalOpen?: boolean,
    isFollowBackUsersModalOpen?: boolean,
    isNonFollowBackUsersModalOpen?: boolean,
    isOneWayFollowsModalOpen?: boolean,
    currentUser: AuthState["currentUser"]
    fetchAnalyticsData: typeof fetchAnalyticsData
}

const Analytics: React.FC<AnalyticsProps> = (props ) => {
    const { currentUser, fetchAnalyticsData } = props
    useEffect(() => {
        if (currentUser?.id && currentUser?.scId) {
          fetchAnalyticsData(currentUser.id, currentUser.scId, currentUser.selectedPlan);
        }
    }, [currentUser?.id, currentUser?.scId, currentUser?.selectedPlan, fetchAnalyticsData]);

    return (
        <StyledContent>
            <AnalyticsDashboard
                isBetrayersModalOpen={props.isBetrayersModalOpen}
                isToFollowUsersModalOpen={props.isToFollowUsersModalOpen}
                isNonFollowBackUsersModalOpen={props.isNonFollowBackUsersModalOpen}
                isFollowBackUsersModalOpen={props.isFollowBackUsersModalOpen}
                isOneWayFollowsModalOpen={props.isOneWayFollowsModalOpen}
            />
            { props.currentUser && props.currentUser.selectedPlan !== 'INTRO' &&
              <ReferralCodeBox referralCode={props.currentUser.scUsername.toUpperCase()}/>
            }
        </StyledContent>
    );
};

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.loading.isLoading,
        currentUser: state.auth.currentUser,
    }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ fetchAnalyticsData }, dispatch)
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Analytics);
