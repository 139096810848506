import { LoadingState } from '../types/States';
import { SetLoadingAction, ReduxAction } from '../types/ReduxActions'
import { LOADING } from '../actions/types';

const INITIAL_STATE: LoadingState = {
    isLoading: true,
    shouldDisplayCaption: false,
};

export default function loadingReducer(state = INITIAL_STATE, action: ReduxAction) {
    switch(action.type) {
        case LOADING:
            const setLoadingAction: SetLoadingAction = action;
            const { isLoading } = setLoadingAction.payload;
            return { ...state, isLoading };
        default:
            return state;
    }
}
