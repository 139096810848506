import React, {ReactNode} from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Button from "./Button";


type HeroProps = {
  backgroundUrl?: string;
  className?: string;
  children: ReactNode[] | ReactNode;
};

const Hero: React.FC<HeroProps> = ({ backgroundUrl, className, children }) => {
  return <div className={className}>{children}</div>;
};

const StyledHero = styled(Hero)`
  text-align: center;
  padding: 50px 0;
  background-size: cover;
  background-position: 50% 30%;

  background-image: ${props => `linear-gradient(
    rgba(247, 191, 77, 0.870588),
    rgba(247, 191, 77, 0.870588)
  ),
  url(${props.backgroundUrl})`};
  
  ${Button} {
    width: 80%;
    padding: 1em 1em;
    ${media.greaterThan("medium")`
      width: auto;
      padding: 1em 3em;
    `}
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0px 0px 9px 0;
    font-weight: 600;
    color: ${props => props.theme.colors.white};
    font-size: 2.5em;
    line-height: 1.25em;
  }

  p {
    margin: 0px 0px 36px 0;
    font-size: 1.2em;
    font-weight: 400;
    padding: 0 15px;
    color: ${props => props.theme.colors.white};
    ${media.greaterThan("medium")`
      font-size: 1.5em;
    `}
  }

  ${media.greaterThan("medium")`
    padding: 110px 0;
  `}
`;

export default StyledHero;
