import React, {useState} from "react";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled, {useTheme} from 'styled-components';
import media from "styled-media-query";

interface RecommendedTrackProps {
  source: string
}

const StyledRecommendedTrackRow = styled.div`
  width: calc(100vw - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: ${props => `1px solid ${props.theme.colors.yellow}`};
  border-radius: 8px;  
  margin-bottom: 16px;
  padding: 10px 20px;
  ${media.greaterThan("medium")`
    width: 400px;
  `}
`

const StyledRecommendedTrack = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;  
  font-size: 14px;
  text-align: left;
  padding: 10px 0;

`;

const CopyToClipboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    position: relative;
`;

const CopyNotificationContainer = styled.div<{ $isClicked: boolean }>`
  text-transform: uppercase;
  font-size: 10px;  
  color: ${props => props.$isClicked ? props.theme.colors.yellow : props.theme.colors.gray};
  position: absolute;
  top: 24px;
`;

const RecommendedTrack: React.FC<RecommendedTrackProps> = ({ source }) => {
  const theme = useTheme()
  const [isClicked, setIsClicked] = useState(false)

  const labelText = isClicked ? 'Copied!' : 'Copy'
  const copyIconStyle = isClicked ? { color: theme.colors.yellow } : { color: theme.colors.gray };

  const handleCopyClicked = (source: string) => {
    copy(source)
    setIsClicked(true)
    setTimeout(() => {
      setIsClicked(false)
    }, 1000)
  }

  return (
    <StyledRecommendedTrackRow>
      <StyledRecommendedTrack>{source}</StyledRecommendedTrack>
      <CopyToClipboardContainer onClick={handleCopyClicked.bind(null, source)}>
        <ContentCopyIcon style={copyIconStyle}/>
        <CopyNotificationContainer $isClicked={isClicked}>
          {labelText}
        </CopyNotificationContainer>
      </CopyToClipboardContainer>
    </StyledRecommendedTrackRow>
  );
};

export default RecommendedTrack;
