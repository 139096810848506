import React from 'react';
import styled from 'styled-components';
import media, { defaultBreakpoints } from 'styled-media-query';

import step1of2desktopImg from '../images/loadingBars/step1of2desktop.svg';
import step2of2desktopImg from '../images/loadingBars/step2of2desktop.svg';

import step1of2mobileImg from '../images/loadingBars/step1of2mobile.svg';
import step2of2mobilepImg from '../images/loadingBars/step2of2mobile.svg';

import step1of3desktopImg from '../images/loadingBars/step1of3desktop.svg';
import step2of3desktopImg from '../images/loadingBars/step2of3desktop.svg';
import step3of3desktopImg from '../images/loadingBars/step3of3desktop.svg';

import step1of3mobileImg from '../images/loadingBars/step1of3mobile.svg';
import step2of3mobileImg from '../images/loadingBars/step2of3mobile.svg';
import step3of3mobileImg from '../images/loadingBars/step3of3mobile.svg';
import { useMediaQuery } from 'react-responsive';

interface ProgressStepIndicatorProps {
    numSteps:  2 | 3,
    currentStep: 1 | 2 | 3,
}

const StyledProgressStep = styled.div<{$loadingBarImage: string}>`
    height: 20px;
    width: 100%;
    background-image: ${({ $loadingBarImage }) => `url(${$loadingBarImage})`};
    background-repeat: no-repeat;
    background-position: center;
    ${media.greaterThan("medium")`
        height: 40px;
    `}
`;

const ProgressStepIndicator: React.FC<ProgressStepIndicatorProps> = ({ numSteps, currentStep }) => {
    const isDesktop = useMediaQuery({
        minWidth: defaultBreakpoints.medium
    });
    const getLoadingBarImage = (): string => {
        if (numSteps === 2 && currentStep === 1 && isDesktop) {
            return step1of2desktopImg;
        }
        else if (numSteps === 2 && currentStep === 2 && isDesktop) {
            return step2of2desktopImg;
        }
        else if (numSteps === 2 && currentStep === 1 && !isDesktop) {
            return step1of2mobileImg;
        }
        else if (numSteps === 2 && currentStep === 2 && !isDesktop) {
            return step2of2mobilepImg;
        }
        else if (numSteps === 3 && currentStep === 1 && isDesktop) {
            return step1of3desktopImg;
        }
        else if(numSteps === 3 && currentStep === 2 && isDesktop) {
            return step2of3desktopImg;
        }
        else if(numSteps === 3 && currentStep === 3 && isDesktop) {
            return step3of3desktopImg;
        }
        else if(numSteps === 3 && currentStep === 1 && !isDesktop) {
            return step1of3mobileImg;
        }
        else if (numSteps === 3 && currentStep === 2 && !isDesktop) {
            return step2of3mobileImg;
        }
        else if(numSteps === 3 && currentStep === 3 && !isDesktop) {
            return step3of3mobileImg;
        }
        else {
            return '';
        }
    };

    return (
        <StyledProgressStep $loadingBarImage={getLoadingBarImage()}/>
    );
};

export default ProgressStepIndicator;