import React from 'react';
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';

const SupportContactInfo = (props: any) => {

  return (
      <p>For questions or help with your account contact support@artistmgmt.org</p>
  );
  // if (props.currentUser) {
  //   return (
  //     <p>For help with your account, contact support@artistmgmt.org</p>
  //   );
  // } else {
  //   return <></>
  // }
};

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.currentUser,
  }
}

export default compose(
  connect(mapStateToProps)
)(SupportContactInfo);
