import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import PlanCard from './PlanCard';
import { SelectedPlanType } from '../types/Users';
import * as keys from '../common/keys';

type PlanSelectorProps = {
    planTypes: 'yearly' | 'monthly',
    includeBasicPlan?: boolean
    selectedPlanName: SelectedPlanType,
    className?: string,
    isDisabled: boolean,
    onCardClick: (plan: SelectedPlanType) => void,
    includeIntroPlan?: boolean
};

const StyledPlansRow = styled.div<{$isDisabled?: boolean}>`
        display: flex;
        align-items: center;
        flex-direction: column;
        ${media.greaterThan("medium")`
            flex-direction: row;
            justify-content: space-evenly;
        `}
        opacity: ${({ $isDisabled }) => $isDisabled ? 0.5 : 1};
    `;

const PlanSelector: React.FC<PlanSelectorProps> = ({ className, planTypes, includeBasicPlan = false, selectedPlanName, isDisabled, onCardClick, includeIntroPlan = false }) => {
    // console.log('showing', planTypes, 'plans')

    const allPlans = [
        {
            name: 'GOLD',
            title: 'Gold',
            unitPriceUSD: keys.plans.GOLD.unitPriceUSD,
            features: [
                'Proprietary algorithm to expose your music to relevant active accounts',
                'Schedule up to 500 active accounts to like and follow in advance',
                'Automatically unfollow accounts that don\'t follow you back within 30 days',
                'Detailed analytics on accounts reached via Artist Management',
                'Option to enable automatic scheduling',
            ],
        },
        {
            name: 'GOLD_YEARLY',
            title: 'Gold',
            unitPriceUSD: keys.plans.GOLD_YEARLY.unitPriceUSD / 12,
            features: [
                'Proprietary algorithm to expose your music to relevant active accounts',
                'Schedule up to 500 active accounts to like and follow in advance',
                'Automatically unfollow accounts that don\'t follow you back within 30 days',
                'Detailed analytics on accounts reached via Artist Management',
                'Option to enable automatic scheduling',
            ],
        },
        {
            name: 'PLATINUM',
            title: 'Platinum',
            unitPriceUSD: keys.plans.PLATINUM.unitPriceUSD,
            features: [
                'Proprietary algorithm to expose your music to relevant active accounts',
                'Schedule up to 1000 active accounts to like and follow in advance',
                'Automatically unfollow all followed accounts within 30 days',
                'Advanced analytics on accounts reached via Artist Management',
                'Option to enable automatic scheduling',
            ]
        },
        {
            name: 'PLATINUM_YEARLY',
            title: 'Platinum',
            unitPriceUSD: keys.plans.PLATINUM_YEARLY.unitPriceUSD / 12,
            features: [
                'Proprietary algorithm to expose your music to relevant active accounts',
                'Schedule up to 1000 active accounts to like and follow in advance',
                'Automatically unfollow all followed accounts within 30 days',
                'Advanced analytics on accounts reached via Artist Management',
                'Option to enable automatic scheduling',
            ]
        }
    ];
    const plans = allPlans.filter(plan => planTypes === 'yearly' ? plan.name.includes('YEARLY') : !plan.name.includes('YEARLY'));


    if (includeBasicPlan && planTypes === 'monthly') {
        plans.unshift({
            name: 'BASIC',
            title: 'Basic',
            unitPriceUSD: keys.plans.BASIC.unitPriceUSD,
            features: [
                'Proprietary algorithm to expose your music to relevant active accounts',
                'Schedule up to 200 active accounts to like and follow in advance',
                'Automatically unfollow accounts that don\'t follow you back within 30 days',
                'Basic analytics on accounts reached via Artist Management',
            ],
        })
    }

    if (includeIntroPlan) {
        for (const plan of plans) {
            plan.features.unshift('Everything in INTRO')
        }
        plans.unshift({
            name: 'INTRO',
            title: 'Intro',
            unitPriceUSD: 0,
            features: [
              'Find out which SoundCloud accounts you are currently following who never followed you back (One-way Follows)'
            ]
        })
    }

    return (
        <StyledPlansRow className={className} $isDisabled={isDisabled}>
            {plans.map(plan => (
                <PlanCard key={plan.name} onCardClick={onCardClick} isSelected={selectedPlanName === plan.name} name={plan.name as Exclude<SelectedPlanType, null>} title={plan.title} monthlyPriceUSD={plan.unitPriceUSD} features={plan.features}/>
            ))}
        </StyledPlansRow>
    );
};

export default PlanSelector;
