export interface CachedArtistEligibilityInputs {
  leadId: string
  soundCloudProfileUrl: string
}

export interface StemMasteringWorkshopReplayInputs {
  firstName: string
  email: string
}

export enum ViewingTokenValidationResult {
  VIEWING_ALLOWED = 'VIEWING_ALLOWED',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INVALID_TOKEN = 'INVALID_TOKEN',
}

export interface CreateOrUpdateLeadResponse {
  leadId: string,
  viewingToken: string,
}