import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import media, { defaultBreakpoints } from "styled-media-query";

import logo from './images/artistmgmt-logo.svg';
import StyledLink from "./StyledLink";
import { RootState } from './types/States';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';

const Header = styled.header``;

const Drawer = styled(animated.div)`
  overflow: hidden;
`;

const SignupLink = styled(StyledLink)`
  background-color: white;
  color: ${props => props.theme.colors.yellow};
  padding: 9px 18px;
  border-radius: 3px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em 1em;

  ${media.greaterThan("medium")`
  padding: 1em;
  `}
`;

const Logo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  > img {
    height: 32px;
  }
`;

const Links = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${StyledLink} {
    margin: 0.75em;

    ${media.greaterThan("medium")`
      margin: 0 0 0 18px;
    `}
  }
`;

const StyledAnchor = styled.a`
  color: white;
  text-decoration: none;
  font-family: Gibson, sans-serif;
  font-weight: 600;
  margin: 0.75em;

  ${media.greaterThan("medium")`
      margin: 0 0 0 18px;
  `}
`

const HamburgerMenu = styled(GiHamburgerMenu).attrs({ color: "white" })`
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.yellow};
`;

const AppHeader: React.FC = (props: any) => {

  const headerContents = () => {
    if (props.isLoading) {
      return [];
    }

    const signedInContents = [
      <StyledAnchor key="blog" target='_blank' rel='noopener' href="https://blog.artistmgmt.org">Blog</StyledAnchor>,
      <SignupLink key="dashboard" to="/dashboard">Dashboard</SignupLink>,
      <StyledLink onClick={props.signout.bind({}, props.currentUser)} key="signout" to="/">sign out</StyledLink>,
    ];

    // if (props.currentUser && props.currentUser.subscription) {
    //   signedInContents.unshift(
    //       <StyledLink key="faq" to="/faq">FAQ</StyledLink>,
    //   )
    // }

    const signedOutContents = [
      <StyledLink key="howitworks" to="/how-it-works">How it works</StyledLink>,
      <StyledAnchor key="blog" target='_blank' rel='noopener' href="https://blog.artistmgmt.org">Blog</StyledAnchor>,
      <StyledLink key="signin" to="/signin">sign in</StyledLink>,
      <SignupLink key="signup" to="/signup">sign up</SignupLink>,
    ];
    return props.currentUser ? signedInContents : signedOutContents;
  };

  const isDesktop = useMediaQuery({
    minWidth: defaultBreakpoints.medium
  });

  const [isOpened, setOpened] = useState(isDesktop);

  const transitions = useTransition(isOpened,  {
    from: { maxHeight: "0vh" },
    enter: { maxHeight: "20vh" },
    leave: { maxHeight: "0vh" }
  });

  useEffect(() => {
    setOpened(false);
  }, [isDesktop, setOpened]);

  return (
    <Container>
      <Header>
        <Content>
          <Logo>
            <StyledLink to="/">
              <Logo>
                <img src={logo} alt="Logo navbar" />
              </Logo>
            </StyledLink>
          </Logo>

          {isDesktop ? (
            <Links>{headerContents()}</Links>
          ) : (
            <HamburgerMenu
              onClick={() => {
                setOpened(s => {
                  return !s;
                });
              }}
            />
          )}
        </Content>
      </Header>
      {transitions((styles, item) => {
        return (
          item && (
            <Drawer style={styles}>
              <Links>{headerContents()}</Links>
            </Drawer>
          )
        );
      })}
    </Container>
  );
};

function mapStateToProps(state: RootState) {
  return {
    currentUser: state.auth.currentUser,
    isLoading: state.loading.isLoading,
  }
}

export default compose(
    connect(mapStateToProps, actions)
)(AppHeader);

