import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 1.1em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
`;
const StyledReferralCode = styled(StyledTitle)`
    margin-top: 5px;
    font-size: 1.25em;  
    color: ${props => props.theme.colors.black};
`;


interface ReferralCodeBoxProps {
  referralCode: string,
}

const StyledReferralCodeBox = styled.div`
    border: 2px dashed gray;
    padding: 20px 0;
    background-color: #FFFCF3;
    border-radius: 25px;
    box-sizing: border-box;
    transition: 0.3s;
    margin-bottom: 20px;
    width: 80%;
    ${media.greaterThan("medium")`
        width: 275px;
    `}
`;

const StyledReferralCodeContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ReferralCodeBox: React.FC<ReferralCodeBoxProps> = ({ referralCode }) => {

  return (
    <StyledReferralCodeContainer>
      <StyledReferralCodeBox>
        <StyledTitle>
          Referral Code:
        </StyledTitle>
        <StyledReferralCode>
          {referralCode}
        </StyledReferralCode>
      </StyledReferralCodeBox>
    </StyledReferralCodeContainer>
  );
};

export default ReferralCodeBox;
