import React, {ReactNode} from 'react';
import Modal from 'react-modal';
import styled from "styled-components";
import media from 'styled-media-query';
import { useMediaQuery } from 'react-responsive';
import { defaultBreakpoints } from 'styled-media-query';
import infoIconImg from './images/icons/info-icon.svg';

const StyledModalHeader = styled.div`
    padding 0.5em 0;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 43px;
    border-bottom: 1px solid rgb(219, 219, 219);
`;

const StyledModalHeaderBlankBox = styled.div`
    flex-basis: 48px;
`;

const StyledModalHeaderCloseButton = styled.div`
    flex-basis: 48px;
    text-align: center;
    cursor: pointer;
`;

const StyledModalHeaderContent = styled.div`
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
`;

const StyledModalContent = styled.div`
    position: relative;
    overflow: scroll;
    height: calc(100% - 43px - 1em);
    ${media.greaterThan("medium")`
        min-height: 200px;
        max-height: calc(400px - 43px - 1em);
    `}
`;

const StyledIconImg = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 5px;
    position: relative;
    top: -2px;
`;

type NewUserModslProps = {
  isModalOpen: boolean;
  afterOpenModal?: () => void;
  closeModal: () => void;
  title?: string,
  children: ReactNode[] | ReactNode
};

const NewUserModal: React.FC<NewUserModslProps> = ({title, isModalOpen, afterOpenModal, closeModal, children} ) => {
  const isDesktop = useMediaQuery({
    minWidth: defaultBreakpoints.medium
  });
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={isDesktop ? modalStylesDesktop : modalStylesMobile}
      contentLabel="Example Modal"
    >
      {
        title &&
        <StyledModalHeader>
          <StyledModalHeaderBlankBox/>
          <StyledModalHeaderContent>
            <StyledIconImg src={infoIconImg} alt="" />
            <div>
              {title}
            </div>
          </StyledModalHeaderContent>
          <StyledModalHeaderCloseButton onClick={closeModal}>
            ×
          </StyledModalHeaderCloseButton>
        </StyledModalHeader>
      }
      <StyledModalContent>
        {children}
      </StyledModalContent>
    </Modal>
  );
};

const modalStylesDesktop: ReactModal.Styles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    minHeight: '200px',
    maxHeight: '400px',
    borderRadius: '20px',
    position: 'relative',
    padding: 0,
  },
  overlay: {
  }
};

const modalStylesMobile: ReactModal.Styles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: 0,
  },
  overlay: {
  }

};
export default NewUserModal;