import React, { useEffect } from 'react'
import * as analyticsHelper from './common/analyticsHelper'
import * as keys from './common/keys'
import {Redirect, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import media from 'styled-media-query'

const InfluencerPayoutPolicy: React.FC = () => {
  const minViews = 1000.0000001
  const maxViews = 1_000_000_000.00001 // 1 billion
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const platform = queryParams.get('platform')


  const [sliderValue, setSliderValue] = React.useState(0.0)
  useEffect(() => {
    analyticsHelper.recordPageView(keys.AmpPageInfluencerPayoutPolicy);
    window.scrollTo(0, 0);
  }, []);


  const handleSliderChange = (event: any) => {
    setSliderValue(event.target.value)
  }

  const getViewCount = (): number => {
    return Math.round(Math.exp(Math.log(minViews) + (Math.log(maxViews)-Math.log(minViews)) * sliderValue))
  }
  const numberWithCommas = (n: number): string => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function getPayoutFormula() {
    let viewsRemaining = getViewCount()
    let numProcessed = 0
    const processedRanges: {pricePerView: number, numViews: number}[] = []
    const payoutRanges: {pricePerView: number, rangeTop: number}[] = [
      {
        pricePerView: 0.07,
        rangeTop: 25_000
      },
      {
        pricePerView: 0.01,
        rangeTop: 50_000
      },
      {
        pricePerView: 0.00325,
        rangeTop: 1_000_000
      },
      {
        pricePerView: 0.000005,
        rangeTop: 1_000_000_000
      }
    ]
    for (const payoutRange of payoutRanges) {
      const numProcessableViews = payoutRange.rangeTop - numProcessed
      if (viewsRemaining < numProcessableViews) {
        processedRanges.push({
          pricePerView: payoutRange.pricePerView,
          numViews: viewsRemaining,
        })
        numProcessed += viewsRemaining
        viewsRemaining = 0
        return processedRanges
      }
      viewsRemaining -= numProcessableViews
      numProcessed += numProcessableViews
      processedRanges.push({
        pricePerView: payoutRange.pricePerView,
        numViews: numProcessableViews,
      })
    }
    return processedRanges
  }

  function calculatePayout() {
    const processedRanges = getPayoutFormula()
    const payout = processedRanges.reduce((runningTotal, currentValue) => {
      return runningTotal + currentValue.pricePerView * currentValue.numViews
    }, 0)
    return {
      payout,
      processedRanges,
    }
  }

  const viewCountLabel = (): React.JSX.Element => {
    const viewCount = getViewCount()
    let label: string
    if (viewCount >= Math.floor(maxViews)) {
      label = "+ Views"
    } else {
      label = ' Views'
    }
    return (
      <ViewCountLabel>
        {`${numberWithCommas(viewCount)}${label}`}
      </ViewCountLabel>
    )
  }

  const payoutWithFormula = (): React.JSX.Element => {
    const { payout, processedRanges} = calculatePayout()
    const formulaString = processedRanges.reduce((runningFormula, currentValue) => {
      if (runningFormula === '') {
        return `${currentValue.pricePerView} x ${currentValue.numViews.toLocaleString()}`
      }
      return `${runningFormula} + $${currentValue.pricePerView} x ${currentValue.numViews.toLocaleString()}`
    }, '')

    return (
      <>
        <FormulaContainer>${formulaString}</FormulaContainer>
        <PayoutContainer>${payout.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2})}</PayoutContainer>
      </>
    )
  }

  if (!platform || platform.toLowerCase() !== 'tiktok') {
    return <Redirect to='/' />
  }

  return (
    <ContentContainer>
      <StyledTitle>TikTok Influencer Payout Calculator</StyledTitle>
      <StyledDiv>
        <StyledHeading>
          Our Payout Formula
        </StyledHeading>
        <StyledText>
          Artist Management strongly believes in fairly compensating influencers who help spread the word about our platform.
          The goal behind this is to:
          <ul>
            <li>Share profits with you for any business that your video brings</li>
            <li>Incentivize you to make the best possible content for your audience, because then we all win</li>
          </ul>
          This is why we have developed a transparent formula to determine the amount you receive for posting your video.
          This formula is based on the <b>number of views that your video has received exactly 30 days after it has gone live.</b>
        </StyledText>
      </StyledDiv>
      {viewCountLabel()}
      <SliderContainer>
        <StyledInput onChange={handleSliderChange} type="range" min="0" max="1" step="0.000000001" value={sliderValue}/>
      </SliderContainer>
      {payoutWithFormula()}
    </ContentContainer>
  )
}

const StyledInput = styled.input`
  accent-color: ${props => props.theme.colors.yellow};

`
const SliderContainer = styled.div`
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    ${media.greaterThan("medium")`
      width: calc(100% - 108px);
    `}`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const StyledTitle = styled.h1`
    margin: 24px 0px 9px 0;
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
    line-height: 1.25em;

    text-align: center;

    text-transform: uppercase;
    font-size: 30px !important;
    ${media.greaterThan("medium")`
      font-size: 2.5em !important;
    `}
`;

const ViewCountLabel = styled.div`
    margin: 0px 0px 12px 0;
    font-size: 1.2em;
    font-weight: 400;
    padding: 0 15px;
    color: ${props => props.theme.colors.black};
    ${media.greaterThan("medium")`
      font-size: 1.5em;
    `}
`

const FormulaContainer = styled.div`
    text-align: center;
    padding: 48px 18px;
`

const PayoutContainer = styled.div`
    margin: -24px 0 24px;
    font-family: 'calculator', sans-serif;
    font-size: 64px;
    color: ${props => props.theme.colors.orange};
    ${media.greaterThan("medium")`
      font-size: 80px;
    `}
`

const StyledDiv = styled.div`
   margin-top: 24px;
   max-width: 300px;
   ${media.greaterThan("medium")`
    max-width: 650px;
  `}
`;

const StyledHeading = styled.h2`
  margin: 0 0 18px 0;
  font-weight: 600;
  font-size: 1.5em;
  color: ${props => props.theme.colors.orange};
  line-height: 1.25em;
  text-align: center;
`;

const StyledText = styled.div`
  margin: 0 0 18px 0;
  h3 {
    font-weight: 600;
    font-size: 1em;
  }
`;

export default InfluencerPayoutPolicy