import React, {useState} from "react";
import { FollowerCount } from "../types/SoundCloudUsers";
import Tab, { TabItem } from './Tab'
import {LineChart} from "@mui/x-charts";
import styled from "styled-components";

type FollowerCountGraphProps = {
  followerCounts: FollowerCount[]
}

const Container = styled.div`
  height: 100%;
  
`

const FollowerCountGraph: React.FC<FollowerCountGraphProps> = ({ followerCounts }) => {
  const [followerCountsToDisplay, setFollowerCountsToDisplay] = useState<FollowerCount[]>(followerCounts.slice(-7))
  const onTabSelected = (index: number) => {
    if (index === 0) {
      setFollowerCountsToDisplay(followerCounts.slice(-7))
    } else {
      setFollowerCountsToDisplay(followerCounts)
    }
  };

  const time = followerCountsToDisplay.map(obj => obj.timestamp)
  const followerCountData = followerCountsToDisplay.map(obj => obj.followerCount)

  const valueFormatter = (date: Date) =>
    date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
      })


  return (
    <Container>
      { followerCounts.length >= 30 &&
        <Tab onTabSelected={onTabSelected}>
          <TabItem>Last 7 days</TabItem>
          <TabItem>Last 30 days</TabItem>
        </Tab>
      }
      { followerCounts.length >= 7 ?
        (
        <LineChart
          xAxis={[
            {
              scaleType: 'point',
              data: time,
              valueFormatter,
              min: time[0].getTime(),
              max: time[time.length - 1].getTime()
            },
          ]}
          series={[
            {
              data: followerCountData,
              color: '#EF6236'
            },
          ]}
          height={300}
        />
        ): (
          <div>
            A graph of your follower count will be plotted here once we have at least 7 days of data.
          </div>
        )
      }
    </Container>
  );
}

export default FollowerCountGraph;